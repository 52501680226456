import { Email, Person, Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  encryptData,
  loginUser,
  rememberedData,
  userAccountAPI,
} from "../../services/services";
import helpers from "../../utils/helpers";

const Login = (props) => {
  // let [searchParams, setSearchParams] = useSearchParams();
  // const callbackUrl = searchParams.get("return");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userType, setUserType] = useState("buyer");
  const [rememberMe, setRememberMe] = useState(false);
  const [load, setLoad] = useState(false);
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [userInputs, setUserInputs] = useState({
    email: { value: "", error: "" },
    password: { value: "", error: "" },
  });

  // useEffect(() => {
  //   const { email } = rememberedData();
  //   if (email) {
  //     setEmail(email);
  //     setRememberMe(true);
  //   }
  // }, []);

  const handleLoginInputs = (e) => {
    const { name: inputTargetName, value: inputTargetValue } = e.target;
    let _errorMsg = "";

    switch (inputTargetName) {
      case "email":
        _errorMsg = !helpers.validateEmail(inputTargetValue)
          ? "Enter a valid email"
          : "";
        break;
      case "password":
        _errorMsg = !helpers.validatePassword(inputTargetValue)
          ? "Enter password"
          : "";
        break;
      default:
        break;
    }

    setUserInputs((prev) => ({
      ...prev,
      [inputTargetName]: {
        value: inputTargetValue,
        error: _errorMsg,
      },
    }));
  };

  const onLoginSubmit = async () => {
    const data = {
      email: userInputs.email.value,
      password: userInputs.password.value,
    };

    setLoad(true);

    try {
      const res = await userAccountAPI.preLogin(data).catch((e) => e);

      if (res.success) {
        dispatch({ type: "SUCCESS", payload: "Verification Successful" });

        const encryptedData = encryptData(JSON.stringify({ email }));
        localStorage.setItem("xyz", encryptedData);

        if (Object.hasOwn(res, "data")) {
          dispatch({ type: "SUCCESS", payload: "Login successful" });

          dispatch({ type: "LOGIN_USER", payload: res.data });
          setLoad(false);
          localStorage.clear("xyz");
          localStorage.setItem("token", res.data.token);
          return navigate("/");
        } else {
          navigate("/account/verification?imperio=" + encryptedData);
        }
      } else {
        dispatch({ type: "ERROR", payload: res });
      }
    } catch (err) {
      dispatch({ type: "ERROR", payload: err || "An error has occurred" });
    } finally {
      setLoad(false);
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "480px",
          bgcolor: "#fff",
          borderRadius: "8px",
          padding: {
            xs: "1rem",
            md: "4rem",
          },
          textAlign: "center",
          boxShadow: "0 10px 30px rgba(0,0,0, 12%)",
          "& > span": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "1rem",
          },
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: 600, mt: "0rem", mb: ".4rem" }}
        >
          Welcome Back !
        </Typography>
        <Typography variant="body1" mb="2rem" color={"GrayText"}>
          {/* Please enter your login details below */}
          Veuillez rentrez vos email et mot de passe!
        </Typography>
        <form onSubmit={onLoginSubmit}>
          {userType === "buyer" && (
            <TextField
              sx={{ mb: "1rem" }}
              fullWidth
              variant="outlined"
              label="Email"
              type="email"
              name="email"
              autoComplete="email"
              error={!!userInputs.email.error}
              helperText={userInputs?.email?.error}
              value={userInputs.email.value}
              onChange={handleLoginInputs}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Email fontSize="small" sx={{ color: grey[400] }} />
                  </InputAdornment>
                ),
              }}
            />
          )}
          <TextField
            sx={{ mb: "1rem" }}
            fullWidth
            variant="outlined"
            label="Mot de Passe"
            type={show ? "text" : "password"}
            name="password"
            autoComplete="current-password"
            error={!!userInputs.password.error}
            helperText={userInputs?.password?.error}
            value={userInputs.password.value}
            onChange={handleLoginInputs}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {show ? (
                    <IconButton onClick={() => setShow(false)}>
                      <Visibility fontSize="small" sx={{ color: grey[400] }} />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => setShow(true)}>
                      <VisibilityOff
                        fontSize="small"
                        sx={{ color: grey[400] }}
                      />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <FormControlLabel
              onChange={() => setRememberMe(!rememberMe)}
              control={<Checkbox checked={rememberMe} />}
              label={
                <Typography variant="body2" color={"GrayText"}>
                  {/* Remember Me */}
                  Se rappeler de moi !
                </Typography>
              }
            />
            <Link to={"/forgot-password"} style={{ textDecoration: "none" }}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, color: "#000" }}
              >
                {/* Forgot Password? */}
                Mot de passe oublié?
              </Typography>
            </Link>
          </Box>
          <LoadingButton
            loading={load}
            type="submit"
            onClick={onLoginSubmit}
            disabled={userInputs.email.error || userInputs.password.error}
            disableElevation
            size="large"
            fullWidth
            variant="contained"
            color="primary"
            sx={{
              textTransform: "none",
              color: "#fff",
              height: "3.5rem",
              mb: "2rem",
            }}
          >
            {/* Login To Account? */}
            Se connecter
          </LoadingButton>
        </form>

        <Typography textAlign={"center"} color={"GrayText"} mb="2rem">
          <span style={{ marginInline: "5px" }}>Pas de compte encore?</span>
          <Link to={"/register"}>
            {/* Register */}
            Créer un compte
          </Link>
        </Typography>
        {/* <Typography variant='body2' color={'GrayText'} textAlign='center'>By continuing, you  agree to our Terms of Use and Privacy Policy</Typography> */}
      </Box>
    </Box>
  );
};

const mapStateToprops = (state) => state;

export default connect(mapStateToprops, { loginUser })(Login);
