import React, { useEffect, useMemo, useState } from "react";

import {
  Avatar,
  Badge,
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Popover,
  Skeleton,
  Slide,
  Stack,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";

import NotificationsIcon from "@mui/icons-material/Notifications";

import Logo from "../../assets/clickso.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Close,
  Euro,
  Facebook,
  Favorite,
  Home,
  Instagram,
  Place,
  Restaurant,
  Search,
  YouTube,
} from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import CartItem from "../Cart/Item/CartItem";
import { connect, useDispatch, useSelector } from "react-redux";
import { signoutUser, userAccountAPI } from "../../services/services";
import RestaurantItem from "../Restaurant/Item/RestaurantItem";
import MenuImage from "../../assets/menus.png";
import MealItem from "../Meal/Item/MealItem";
import "./Navbar.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { initiateSocketConnectionAndListen } from "../../services/socket";
import LanguageSelector from "../LanguageSelector/main";
import { useTranslation } from "react-i18next";

const NavLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.text.main,
  ":hover": {
    color: theme.palette.secondary.main,
  },
}));

const FlexBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "2rem",
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Navbar = (props) => {
  const { signoutUser } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.currentUser);
  const cart = useSelector((state) => state.cart);
  const notifications = useSelector((state) => state.resto.notifications);
  const addedToFavorite = useSelector((state) => state.resto.favoritesAdded);
  const numberOfNotifications = useMemo(
    () => recomputeNotificationsRead(notifications),
    [notifications]
  );
  const favorites = useSelector((state) => state.currentUser?.favourites);
  const restaurants = useSelector((state) => state.resto.restaurants?.results);
  const dishes = useSelector((state) => state.resto.dishes?.results);
  const [cartTotal, setCartTotal] = useState();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterValue, setFilterValue] = useState("");
  const [dataDishes, setDataDishes] = useState([]);
  const [dataResto, setDataResto] = useState([]);
  const location = useLocation();
  const { t } = useTranslation();

  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const openAnchor = Boolean(anchorEl2);
  const handleClick = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleCloseAnchor2 = () => {
    setAnchorEl2(null);
  };

  useEffect(() => {
    if (currentUser) {
      userAccountAPI.getReviewUserSelf();
      // GET USER FAVORITES
      const fetchFav = async () => {
        try {
          const res = await userAccountAPI.getFav();

          dispatch({ type: "UPDATE FAVORITES", payload: res?.data });
        } catch (e) {
          dispatch({ type: "ERROR", payload: "Could not fetch favorites" });
        }
      };

      // Initiate socket connection
      initiateSocketConnectionAndListen(currentUser, dispatch);

      fetchFav();
    }
  }, [currentUser, dispatch]);

  useEffect(() => {
    const amt = cart?.items?.reduce((acc, value) => acc + value?.total, 0);
    setCartTotal(amt?.toFixed(2));
  }, [cart, currentUser, dispatch]);

  useEffect(() => {
    setOpen(false);
    setFilterValue("");
  }, [location]);

  useEffect(() => {
    setTimeout(() => {
      if (filterValue.length <= 0) {
        setDataDishes(dishes);
        setDataResto(restaurants);
      } else {
        const newDishesData = dishes.filter((d) =>
          JSON.stringify(d)
            .toLowerCase()
            .includes(filterValue.trim().toLowerCase())
        );
        const newRestoData = restaurants.filter((d) =>
          JSON.stringify(d)
            .toLowerCase()
            .includes(filterValue.trim().toLowerCase())
        );
        setDataDishes(newDishesData);
        setDataResto(newRestoData);
      }
    }, 1000);
  }, [filterValue, dishes, restaurants]);

  // Popover states
  const open2 = Boolean(anchorEl);

  const toCheckout = () => {
    dispatch({ type: "DRAW_CLOSE" });
    navigate("/checkout", { state: { cartTotal } });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function recomputeNotificationsRead(notifications) {
    const notificationsRead = notifications.filter((n) => n.isRead === false);
    return notificationsRead.length;
  }

  return (
    <>
      <Box bgcolor={"#000"} sx={{ padding: "10px 0" }}>
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: "rgba(225,225,225, 0.8)",
          }}
        >
          <Stack direction={"row"} gap={".6rem"} alignItems={"center"}>
            <Place sx={{ fontSize: "1rem" }} />
            <Typography variant="body2" color="white">
              71 Madison Avenue, Lyon
            </Typography>
          </Stack>
          <Stack direction={"row"} gap={"1.2rem"}>
            <Facebook sx={{ fontSize: "1.2rem" }} />
            <Instagram sx={{ fontSize: "1.2rem" }} />
            <YouTube sx={{ fontSize: "1.2rem" }} />
            <LanguageSelector></LanguageSelector>
          </Stack>
        </Container>
      </Box>
      <Box
        sx={{
          borderBottom: (theme) => `1px solid ${grey[300]}`,
          padding: "10px 0",
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            marginBottom: {
              xs: "1rem",
              sm: "0",
            },
          }}
        >
          <Stack
            direction={"row"}
            display={"flex"}
            flexWrap={"wrap"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <FlexBox
              flex={1}
              sx={{
                display: {
                  xs: "none",
                  sm: "inline-flex",
                },
                justifyContent: {
                  xs: "center",
                  lg: "flex-start",
                },
              }}
            >
              <Box
                className="logo-container"
                flex={1}
                justifyContent={"center"}
                textAlign={"center"}
                sx={{
                  marginBottom: {
                    xs: "5px",
                    md: 0,
                  },
                  display: {
                    xs: "block",
                    md: "block",
                  },
                }}
              >
                <img
                  className="navbar-logo"
                  src={Logo}
                  alt="logo"
                  // width={"15%"}
                  width="100px"
                />
              </Box>

              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap={4}
              >
                <NavLink to={"/"}>
                  <Typography>{t("Navbar.Home")}</Typography>
                </NavLink>

                <NavLink to={"/restaurants"}>
                  <Typography>{t("Navbar.Restaurants")}</Typography>
                </NavLink>

                <NavLink to={"/about-us"}>
                  <Typography>{t("Navbar.About")}</Typography>
                </NavLink>

                <NavLink to={"/contact-us"}>
                  <Typography>{t("Navbar.Contact Us")}</Typography>
                </NavLink>
              </Box>

              {/* <NavLink to={'/meals'}><Typography>Meals</Typography></NavLink> */}
            </FlexBox>

            <FlexBox
              flex={1}
              sx={{
                justifyContent: {
                  xs: "flex-end",
                  lg: "flex-end",
                },
                marginBottom: {
                  xs: "0rem",
                  sm: "0",
                },
              }}
            >
              <Stack direction={"row"} gap={"1rem"}>
                {currentUser ? (
                  <>
                    <Badge
                      badgeContent={
                        cart?.items?.length > 0 ? cart?.items?.length : null
                      }
                      variant="standard"
                      color="secondary"
                      showZero
                      overlap="circular"
                    >
                      <IconButton
                        onClick={() => dispatch({ type: "DRAW_OPEN" })}
                        sx={{
                          border: "1px solid lightgrey",
                          color: "text.main",
                        }}
                      >
                        <Restaurant fontSize="small" />
                      </IconButton>
                    </Badge>
                    <Badge
                      badgeContent={
                        favorites?.length > 0 ? favorites?.length : null
                      }
                      variant="standard"
                      color="secondary"
                      showZero
                      overlap="circular"
                    >
                      <IconButton
                        sx={{
                          border: "1px solid lightgrey",
                          color: "text.main",
                        }}
                        onClick={() => {
                          navigate("/account/favorites");
                        }}
                      >
                        <Favorite
                          fontSize="small"
                          sx={{
                            color: favorites?.length > 0 ? "red" : "",
                          }}
                        />
                      </IconButton>
                    </Badge>
                    <Badge
                      badgeContent={
                        numberOfNotifications > 0 ? numberOfNotifications : null
                      }
                      variant="standard"
                      color="secondary"
                      showZero
                      overlap="circular"
                    >
                      <IconButton
                        sx={{
                          border: "1px solid lightgrey",
                          color: "text.main",
                        }}
                        onClick={() => {
                          navigate("/account/notifications");
                        }}
                      >
                        <NotificationsIcon fontSize="medium" />
                      </IconButton>
                    </Badge>
                  </>
                ) : (
                  <></>
                )}
                <IconButton
                  sx={{
                    border: "1px solid lightgrey",
                    color: "text.main",
                    display: {
                      xs: "none",
                      sm: "block",
                    },
                  }}
                  onClick={() => setOpen(true)}
                >
                  <Search fontSize="small" />
                </IconButton>
              </Stack>
              {currentUser ? (
                <>
                  <Avatar
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    sx={{
                      bgcolor: "primary.main",
                      ml: "-1rem",
                      cursor: "pointer",
                    }}
                  >
                    {currentUser?.username?.charAt(0)?.toUpperCase()}
                  </Avatar>
                  <Popover
                    sx={{ mt: 1 }}
                    open={open2}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                  >
                    <Box width="16rem">
                      <Box padding={"1.5rem"} textAlign="center">
                        <Box
                          position={"relative"}
                          width="fit-content"
                          m={"0 auto"}
                        >
                          <Avatar
                            src={currentUser?.photo ? currentUser?.photo : null}
                            sx={{
                              ml: 1,
                              height: "4rem",
                              width: "4rem",
                              m: "0 auto",
                              bgcolor: "primary.main",
                            }}
                          >
                            {currentUser?.photo ? null : (
                              <Typography variant="h3">
                                {currentUser?.username?.charAt(0).toUpperCase()}
                              </Typography>
                            )}
                          </Avatar>
                        </Box>
                        <Typography variant="h6" mt={1}>
                          {currentUser?.username}
                        </Typography>
                        <Typography
                          variant="body2"
                          color={"GrayText"}
                          noWrap
                          mb={1}
                        >
                          {currentUser?.email}
                        </Typography>
                      </Box>
                      <Divider sx={{ bgcolor: grey[100] }} />
                      {currentUser && (
                        <Box padding={"1rem"}>
                          <MenuItem
                            component="a"
                            sx={{ borderRadius: "8px", fontSize: ".9rem" }}
                            onClick={() => navigate("/account/me")}
                          >
                            {/* My Account */}
                            {t("Navbar.ProfileMenu.My Profile")}
                          </MenuItem>
                          <MenuItem
                            component="a"
                            sx={{ borderRadius: "8px", fontSize: ".9rem" }}
                            onClick={() =>
                              navigate("/account/orders?q=ordered")
                            }
                          >
                            {/* My Orders */}
                            {t("Navbar.ProfileMenu.My Orders")}
                          </MenuItem>
                          <MenuItem
                            component="a"
                            sx={{ borderRadius: "8px", fontSize: ".9rem" }}
                            onClick={() => navigate("/account/favorites")}
                          >
                            {/* Favorites */}
                            {t("Navbar.ProfileMenu.My Favorites")}
                          </MenuItem>
                        </Box>
                      )}
                      <Divider sx={{ bgcolor: grey[100] }} />
                      <Box padding={"1.5rem 2rem"} textAlign={"center"}>
                        <Button
                          variant="outlined"
                          color="secondary"
                          disableElevation
                          sx={{
                            textTransform: "none",
                            background: "transparent",

                            "&:hover": {
                              color: "#fff",
                              background: (theme) =>
                                theme.palette.secondary.main,
                            },
                          }}
                          onClick={() => signoutUser()}
                        >
                          {/* Sign Out */}
                          {t("Sign Out")}
                        </Button>
                      </Box>
                    </Box>
                  </Popover>
                </>
              ) : (
                <Stack
                  sx={{
                    display: {
                      xs: "none",
                      sm: "block",
                    },
                  }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    onClick={() => navigate("/login")}
                    sx={{ textTransform: "none", borderRadius: "10px" }}
                  >
                    {/* Login/Register */}
                    Se connecter
                  </Button>
                  {/* <NavLink><Typography>Login</Typography></NavLink> */}
                  {/* <NavLink><Typography>Register</Typography></NavLink> */}
                </Stack>
              )}
              <Tooltip
                title="Menu"
                sx={{
                  display: {
                    sm: "none",
                  },
                }}
              >
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{
                    ml: 2,
                    display: {
                      sm: "none",
                    },
                  }}
                  aria-controls={openAnchor ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openAnchor ? "true" : undefined}
                >
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl2}
                id="account-menu"
                open={openAnchor}
                onClose={handleCloseAnchor2}
                onClick={handleCloseAnchor2}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&::before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                    "& hr": {
                      display: "none",
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  onClick={() => {
                    navigate("/");
                    handleCloseAnchor2();
                  }}
                >
                  {/* Home */}
                  {t("Navbar.Home")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/restaurants");
                    handleCloseAnchor2();
                  }}
                >
                  {t("Navbar.Restaurants")}
                </MenuItem>
                {!currentUser && (
                  <MenuItem
                    onClick={() => {
                      navigate("/login");
                      handleCloseAnchor2();
                    }}
                  >
                    {t("Sign In")}/ {t("Sign Up")}
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    setOpen(true);
                    handleCloseAnchor2();
                  }}
                >
                  Search
                </MenuItem>
                <Divider />
              </Menu>
            </FlexBox>
          </Stack>
        </Container>
      </Box>

      {/* SEARCH BAR */}
      <Dialog
        open={open}
        fullScreen
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
      >
        <DialogContent>
          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px",
                background: "#fff",
              },
            }}
            fullWidth
            variant="outlined"
            placeholder="Menus, Restaurants"
            onChange={(e) => setFilterValue(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search fontSize="small" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {" "}
                  <IconButton onClick={() => setOpen(false)}>
                    <Close fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Typography
            sx={{ textAlign: "center", marginTop: "1rem" }}
            variant="body2"
            color="textSecondary"
          >
            {/* Start typing to find what you are looking for */}
            Recherchez vous quelque chose en particulier?
          </Typography>

          {/* SEARCH RESULTS */}
          {filterValue.length > 0 && (
            <Box>
              <Box padding={"6rem 0"}>
                <Container maxWidth="xl">
                  <Typography
                    variant="h4"
                    sx={{ fontWeight: "600" }}
                    mb={"2rem"}
                    textAlign={"center"}
                  >
                    Restaurants
                  </Typography>

                  <Box
                    key={dataResto}
                    id="restaurants"
                    sx={{ mb: "2rem", mt: "2rem", display: "block" }}
                  >
                    <Grid container spacing={4}>
                      {false ? (
                        [1, 2, 3, 4, 5, 6, 7, 8].map((el) => {
                          return (
                            <Grid key={el} item xs={12} sm={6} lg={3}>
                              <Skeleton
                                sx={{ height: "22rem", mt: "-5.5rem" }}
                                animation="wave"
                              />
                            </Grid>
                          );
                        })
                      ) : dataResto?.length > 0 ? (
                        dataResto?.map((meal) => {
                          return (
                            <Grid item xs={12} sm={6} lg={3} key={meal?.id}>
                              <RestaurantItem
                                view={"grid"}
                                name={meal?.name}
                                id={meal?.id}
                                image={meal?.gallery[0]?.url}
                                deliveryTime={meal?.delivery?.time}
                                rating={meal?.avgRating}
                                serviceFee={meal?.service_fee}
                                discount={meal?.discount}
                                dataX={meal}
                              />
                            </Grid>
                          );
                        })
                      ) : (
                        <Box
                          textAlign={"center"}
                          padding={"4rem"}
                          width={"100%"}
                        >
                          <img src={MenuImage} width={"12%"} alt="no_menu" />
                          <Typography variant="h6" mt={3}>
                            No Restaurants
                          </Typography>
                          <Typography variant="body1" color={"GrayText"}>
                            Sorry there are no corresponding restaurants for
                            this search{" "}
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                  </Box>
                </Container>
              </Box>
              <Box>
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "600" }}
                  mb={"2rem"}
                  textAlign={"center"}
                >
                  Dishes
                </Typography>
                <Box id="dishes" key={dataDishes}>
                  <Grid container spacing={3}>
                    {dataDishes?.length > 0 ? (
                      dataDishes?.map((el, index) => {
                        return (
                          <Grid item xs={12} sm={6} lg={3} key={index}>
                            <MealItem
                              name={el?.name}
                              image={el?.image?.url}
                              price={el?.price}
                              description={el?.description}
                              // discount={data?.discount}
                              onClick={() =>
                                navigate(`restaurants/${el?.restaurant?._id}`)
                              }
                            />
                          </Grid>
                        );
                      })
                    ) : (
                      <Box textAlign={"center"} padding={"4rem"} width={"100%"}>
                        <img src={MenuImage} width={"12%"} alt="no_menu" />
                        <Typography variant="h6" mt={3}>
                          No Dishes
                        </Typography>
                        <Typography variant="body1" color={"GrayText"}>
                          Sorry there are no corresponding dishes for this
                          search{" "}
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                </Box>
              </Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>

      {/* Food Cart */}
      <Drawer
        anchor="right"
        open={cart?.open}
        onClose={() => {
          dispatch({ type: "DRAW_CLOSE" });
        }}
        sx={{
          "& .MuiPaper-root": {
            width: {
              xs: "90%",
              sm: "fit-content",
            },
            padding: {
              xs: "1rem",
              sm: "2rem",
            },
            position: {},
          },
        }}
      >
        <Box
          height={"100%"}
          padding={"2rem"}
          sx={{
            width: {
              xs: "100%",
              sm: "20rem",
            },
          }}
        >
          <Box
            fullWidth
            justifyContent={"flex-end"}
            sx={{
              display: {
                xs: "flex",
                sm: "none",
              },
            }}
          >
            <IconButton
              onClick={() => dispatch({ type: "DRAW_CLOSE" })}
              size="small"
              sx={{
                ml: "auto",
                border: "1px solid lightgrey",
                ":hover": { bgcolor: "primary.main", color: "#fff" },
              }}
            >
              <Close sx={{ fontSize: ".8rem" }} />
            </IconButton>
          </Box>
          <Typography variant="h6" sx={{ fontSize: "1.1rem" }} gutterBottom>
            Food Cart({cart?.items?.length})
          </Typography>
          <Divider />
          {cart?.items?.map((el, index) => {
            return (
              <CartItem
                key={el?._id}
                id={el?._id}
                name={el?.title}
                image={el?.image}
                price={el?.price}
                // extras={el?.extras}
                total={el?.price * el?.quantity}
                quantity={el?.quantity}
              />
            );
          })}
          <Box
            sx={{
              padding: "1.5rem 2rem",
              bgcolor: "#fff",
              display: "flex",
              gap: "5rem",
              position: "sticky",
              bottom: 0,
              right: 0,
              alignItems: "center",
              justifyContent: "space-between",
              borderTop: `1px solid ${grey[200]} `,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              disabled={cart?.items?.length > 0 ? false : true}
              onClick={toCheckout}
              disableElevation
              sx={{ height: "3rem" }}
              fullWidth
            >
              CheckOut - {cartTotal || 0} €
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

const mapStateToprops = (state) => state;

export default connect(mapStateToprops, { signoutUser })(Navbar);
