import React from "react";
import {
  Container,
  Typography,
  Box,
  Paper,
  Grid,
  IconButton,
  Button,
} from "@mui/material";
import { styled } from "@mui/system";
import { Fade, Zoom } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
  backgroundColor: "#f1f8e9",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(4),
  backgroundColor: "#ff7043",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#ff8a65",
  },
}));

const IconContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: theme.spacing(4),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const ContactInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: theme.spacing(2),
}));

const ContactUs = () => {
  // TODO Work to do on UI
  /**
   * Add a form to send a message to the support team
   * Add a FAQ page
   */
  return (
    <Grid container spacing={2} sx={{padding: 5}}>
      <Grid item xs={12} md={5}>
        <Fade in timeout={1000} sx={{ml: 2, mb:2}}>
          <StyledPaper elevation={3} sx={{ marginBottom: 4 }}>
            <Box textAlign="center" mb={4}>
              <Typography variant="h4" component="h1" gutterBottom>
                Contactez-nous
              </Typography>
              <Typography variant="body1">
                Pour toute question ou assistance, n'hésitez pas à nous
                contacter via les canaux suivants :
              </Typography>
            </Box>

            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <Zoom in timeout={1000}>
                  <ContactInfo>
                    <PhoneIcon
                      sx={{ fontSize: 40, color: "#ff7043", marginRight: 2 }}
                    />
                    <Typography variant="h6" component="p">
                      +33 1 23 45 67 89
                    </Typography>
                  </ContactInfo>
                </Zoom>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Zoom in timeout={1200}>
                  <ContactInfo>
                    <PhoneIcon
                      sx={{ fontSize: 40, color: "#ff7043", marginRight: 2 }}
                    />
                    <Typography variant="h6" component="p">
                      +33 6 12 34 56 78
                    </Typography>
                  </ContactInfo>
                </Zoom>
              </Grid>
              <Grid item xs={12}>
                <Zoom in timeout={1400}>
                  <ContactInfo>
                    <EmailIcon
                      sx={{ fontSize: 40, color: "#ff7043", marginRight: 2 }}
                    />
                    <Typography variant="h6" component="p">
                      support@clickso.fr
                    </Typography>
                  </ContactInfo>
                </Zoom>
              </Grid>
            </Grid>

            <IconContainer>
              <Zoom in timeout={1600}>
                <IconButton
                  href="https://facebook.com"
                  aria-label="Facebook"
                  sx={{ color: "#3b5998", fontSize: 40 }}
                >
                  <FacebookIcon fontSize="inherit" />
                </IconButton>
              </Zoom>
              <Zoom in timeout={1800}>
                <IconButton
                  href="https://twitter.com"
                  aria-label="Twitter"
                  sx={{ color: "#1da1f2", fontSize: 40 }}
                >
                  <TwitterIcon fontSize="inherit" />
                </IconButton>
              </Zoom>
              <Zoom in timeout={2000}>
                <IconButton
                  href="https://instagram.com"
                  aria-label="Instagram"
                  sx={{ color: "#e4405f", fontSize: 40 }}
                >
                  <InstagramIcon fontSize="inherit" />
                </IconButton>
              </Zoom>
              <Zoom in timeout={2200}>
                <IconButton
                  href="https://linkedin.com"
                  aria-label="LinkedIn"
                  sx={{ color: "#0077b5", fontSize: 40 }}
                >
                  <LinkedInIcon fontSize="inherit" />
                </IconButton>
              </Zoom>
            </IconContainer>

            <Box textAlign="center">
              <StyledButton variant="contained" size="large">
                Envoyez-nous un message
              </StyledButton>
            </Box>
          </StyledPaper>
        </Fade>
      </Grid>
    </Grid>
  );
};

export default ContactUs;
