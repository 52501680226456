import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

function NotificationDetails({ data }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();


  return (
    <Box
      height={"30rem"}
      width={"100%"}
      sx={{
        paddingInline: "1.5rem",
        borderRadius: "10px",
        bgcolor: "#fff",
        position: "relative",
      }}
    >
      <Typography variant="h6">
        {t("NotificationPage.NotificationDetails")}
      </Typography>
      <Stack
        mt={3}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"baseline"}
      >
        <Typography sx={{ fontSize: "1rem" }} color={"GrayText"}>
          Date
        </Typography>
        <Typography
          sx={{ fontSize: ".9rem" }}
          width={"60%"}
          color={"GrayText"}
          textAlign={"right"}
        >
          {new Date(data?.createdAt).toUTCString().split('GMT')}
        </Typography>
      </Stack>
      <Stack
        mt={3}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"baseline"}
      >
        <Typography sx={{ fontSize: ".9rem" }} color={"GrayText"}>
          Subject
        </Typography>
        <Typography
          sx={{ fontSize: "1rem" }}
          width={"60%"}
          color={"GrayText"}
          textAlign={"right"}
        >
          {data?.subject}
        </Typography>
      </Stack>
      <Stack
        mt={3}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"baseline"}
      >
        <Typography sx={{ fontSize: "1rem" }} color={"GrayText"}>
          Message
        </Typography>
        <Typography
          sx={{ fontSize: ".9rem" }}
          width={"60%"}
          color={"GrayText"}
          textAlign={"right"}
        >
          {data?.body}
        </Typography>
      </Stack>
    </Box>
  );
}

export default NotificationDetails;
