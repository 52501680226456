import React, { useEffect, useMemo, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { useNavigate } from "react-router-dom";
import "../../../css/animations.css";
import { listenToRestaurantOpeningStatusUpdate } from "../../../services/socket";

// IMAGES
import Burger from "../../../assets/h7_burger.png";
import FriedChicken from "../../../assets/rev_home.png";
import Tomato from "../../../assets/h1_tomato.png";
import FrenchFries from "../../../assets/french-fry.png";
import Burger2 from "../../../assets/h2_burger.png";
import BhShape from "../../../assets/h1_shape-1.png";
import CircleShape from "../../../assets/h1_circle-1.png";
import CircleGrey from "../../../assets/h1_circle-2.png";
import CircleBg from "../../../assets/h1_banner2.jpg";
import CircleLoc from "../../../assets/h1_location.png";
import Crispy from "../../../assets/h1_chicken.png";
import Salad from "../../../assets/h1_salad.png";
import BgBurger from "../../../assets/h1_burger-1.png";
import MenuImage from "../../../assets/menus.png";
import Pizza from "../../../assets/category-3.png";

// ICONS AND COLORS
import { ShoppingBagOutlined } from "@mui/icons-material";
import { grey } from "@mui/material/colors";

// COMPONENTS
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  LinearProgress,
  Skeleton,
  Stack,
  Typography,
  alpha,
  styled,
} from "@mui/material";
// import Banner from '../components/Banner'
import MealItem from "../../../components/Meal/Item/MealItem";
import RestaurantItem from "../../../components/Restaurant/Item/RestaurantItem";
import DishCard from "../../../components/DishCard/dishCard.main";
import DishGrid from "../../../components/DishCard/dishCard.main";

import {
  homePageAPI,
  restaurantAPI,
  userAccountAPI,
} from "../../../services/services";
import { useDispatch, useSelector } from "react-redux";
import { categories } from "../../../constant";
import "./Homepage.css";
import { useTranslation } from "react-i18next";

// Styled components for animations
const FlexBox1 = styled(Box)({
  position: "relative",
  padding: "2rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  overflow: "hidden",
  height: "400px",
  "& img": {
    transition: "transform 0.5s ease-in-out",
  },
  "&:hover img": {
    transform: "scale(1.1)",
  },
});

const AnimatedTypography = styled(Typography)({
  animation: "fadeInUp 1s ease-in-out",
  "@keyframes fadeInUp": {
    "0%": {
      opacity: 0,
      transform: "translateY(20px)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0)",
    },
  },
});

const AnimatedButton = styled(Button)({
  animation: "bounceIn 1s ease-in-out",
  "@keyframes bounceIn": {
    "0%": {
      transform: "scale(0.9)",
      opacity: 0,
    },
    "70%": {
      transform: "scale(1.1)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(1)",
    },
  },
  "&:hover": {
    animation: "none", // Stop the animation on hover
  },
});

const boxColors = [
  "filter: invert(18%) sepia(36%) saturate(7293%) hue-rotate(344deg) brightness(99%) contrast(105%)",
  "filter: invert(53%) sepia(54%) saturate(3315%) hue-rotate(154deg) brightness(98%) contrast(100%)",
  "filter: invert(74%) sepia(10%) saturate(2036%) hue-rotate(37deg) brightness(101%) contrast(87%)",
  "filter: invert(90%) sepia(39%) saturate(2604%) hue-rotate(318deg) brightness(98%) contrast(102%)",
];

const boxContent = [
  {
    image: Burger2,
  },
  {
    image: FriedChicken,
  },
  {
    image: "",
  },
  {
    image: "",
  },
];
const PropertySlider = styled(OwlCarousel)(({ theme }) => ({
  position: "relative",
  "& .owl-carousel": {
    position: "relative",

    "&:hover .owl-nav": {
      opacity: 1,
    },
    "& .owl-item img": {
      width: "20%",
    },
  },
  "& .owl-nav": {
    // opacity: 0,
    width: "100%",
    position: "absolute",
    display: "flex",
    top: "40%",
    justifyContent: "space-between",
    transform: "TranslateY(50%)",
    transition: "all .2s ease-in",
  },
  "& .owl-prev": {
    padding: "1rem",
    background: alpha("#000", 0.5),
    borderRadius: "15px",
    color: "#fff",
  },
  "& .owl-next": {
    padding: "1rem",
    background: alpha("#000", 0.5),
    borderRadius: "15px",
    color: "#fff",
  },
}));

const FlexBox = styled(Box)({
  padding: "3rem",
  position: "relative",
  overflow: "hidden",
  color: "#fff",
  height: "23rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
});

const Homepage = () => {
  // TODO Need work on the main page
  /**
   * Work on the hero section
   * Work on the deals section
   * Come up with different other ui
   */
  // REDUX STORE
  // const currentUser = useSelector((state) => state?.currentUser);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState([]);
  const [dishes, setDishes] = useState(false);
  const [constDishes, setConstDishes] = useState(false);
  const [or, setOpenRestaurants] = useState([]);
  const [withDiscount, setWithDiscount] = useState([]);
  const [newArrivals, setNewArrivals] = useState([]);
  const [cc, setCC] = useState("Pizza");

  const dispatch = useDispatch();

  const CategoriesMapped = useMemo(
    () =>
      categories.map((category) => {
        return category.title === cc
          ? {
              ...category,
              sx: {
                color: "secondary.main",
                // cursor: "pointer",
                // backgroundImage: `url(${CircleShape})`,
                // backgroundPosition: "center",
                // backgroundRepeat: "no-repeat",
                // backgroundSize: "130px",
                transition: "all .5s ease-in-out !important",
              },
            }
          : {
              ...category,
              sx: {
                height: 400,
                marginInline: 2,
              },
            };
      }),
    [cc]
  );

  useEffect(() => {
    Promise.all([
      homePageAPI.getRestaurantsWithFilters({ isOpen: "true" }),
      homePageAPI.getRestaurantsWithFilters({ discount: "true" }),
      homePageAPI.getNewArrivals(),
      homePageAPI.getDishesByCategory(cc),
    ])
      .then((values) => {
        setOpenRestaurants(values[0]?.data);
        setWithDiscount(values[1]?.data);
        setNewArrivals(values[2]?.data);
        setDishes(values[3]?.data);
        setIsLoading(false);
      })
      .catch((e) => {
        dispatch({ type: "ERROR", payload: "An Error occurred" });
        setIsLoading(false);
      });
  }, [dispatch]);

  const { t } = useTranslation();

  return (
    <Box paddingTop={4}>
      {/* Banner */}
      <Grid container spacing={2} padding={4}>
        <Grid item xs={12} xl={6}>
          <Box
            sx={{
              height: "80vh",
              // width: "80vw",
              borderRadius: "50px",
              marginInline: "auto",
              backgroundImage: `url(${BhShape})`,
              backgroundSize: "contain",
              backgroundPosition: "center right",
              bgcolor: grey[900],
            }}
          >
            <Container maxWidth="xl">
              <Grid container columnSpacing={5}>
                <Grid
                  item
                  sm={5}
                  sx={{
                    height: "80vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                  className="slide-in-left"
                >
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#fff",
                      fontWeight: 600,
                      mb: 2,
                      // fontSize: "3.5em",
                      fontSize: "4vw",
                    }}
                  >
                    {t("HomePage.HeroMain.Title")}
                  </Typography>
                  <Typography sx={{ mb: "2rem", color: "#fff" }}>
                    {" "}
                    {t("HomePage.HeroMain.SubTitle")}
                  </Typography>
                  <span
                    className="wobble-hor-bottom"
                    style={{
                      display: "flex",
                      gap: "1rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      size="large"
                      disableElevation
                      color="secondary"
                      startIcon={<ShoppingBagOutlined />}
                      href="#restaurants"
                      sx={{
                        color: "#fff",
                        fontSize: "1.2rem",
                      }}
                    >
                      {/* ORDER NOW */}
                      {t("Order")}
                    </Button>
                  </span>
                </Grid>
                <Grid
                  item
                  sm={7}
                  sx={{
                    display: {
                      xs: "none",
                      sm: "flex",
                    },
                    justifyContent: "flex-end",
                    alignItems: "center",
                    position: "relative",
                    // overflow: "hidden",
                  }}
                >
                  <img
                    src={Burger}
                    alt="food"
                    width={"100%"}
                    style={{ zIndex: 99 }}
                    className="bounce-in-top"
                  />
                  <img
                    src={Tomato}
                    alt="food"
                    width={"50%"}
                    style={{ position: "absolute", top: "-15%", left: 0 }}
                    className="scale-up-center"
                  />
                  <img
                    src={FrenchFries}
                    alt="food"
                    width={"50%"}
                    style={{ position: "absolute", top: "10%", right: 0 }}
                    className="scale-in-ver-bottom"
                  />
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Grid>
        <Grid item xs={12} xl={6} sx={{ display: "none" }}>
          <Grid container spacing={2}>
            {Array(4)
              .fill(0)
              .map((n, index) => (
                <Grid
                  key={n + index}
                  justifyContent="center"
                  alignItems="center"
                  item
                  xs={12}
                  sm={6}
                >
                  <Box
                    sx={{
                      height: "35vh",
                      overflow: "hidden",
                      position: "relative",
                      borderRadius: "50px",
                      marginInline: "auto",
                      backgroundImage: `url(${BhShape})`,
                      backgroundSize: "contain",
                      backgroundPosition: "center right",
                      bgcolor: grey[900],
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: "bold",
                        color: "white",
                        position: "absolute",
                        left: 25,
                        top: 15,
                      }}
                    >
                      Super Combo Burger
                    </Typography>
                    <img
                      src={boxContent[index]?.image}
                      alt="food"
                      width={"90%"}
                      style={{
                        zIndex: 99,
                        position: "absolute",
                        bottom: -75,
                        right: -120,
                      }}
                    />
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>

      {/* <DishGrid></DishGrid> */}

      <Box padding={"2rem 0"} id="restaurantsOpen">
        <Container maxWidth="xl" textalign={"center"}>
          <Typography
            variant="h4"
            sx={{ fontWeight: "600" }}
            mb={"1rem"}
            textalign={"center"}
          >
            {t("Open Restaurants")}
          </Typography>

          <Box
            sx={{
              mb: "1rem",
              mt: "2rem",
              display: "block",
              position: "relative",
              marginInline: "auto",
            }}
          >
            <Grid container spacing={4}>
              {isLoading ? (
                [1, 2, 3, 4, 5, 6, 7, 8].map((el) => {
                  return (
                    <Grid
                      key={el + "fmeoirfmio"}
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      sx={{
                        padding: "1 !important",
                      }}
                    >
                      <Skeleton
                        sx={{ height: "22rem", mt: "-5.5rem" }}
                        animation="wave"
                      />
                    </Grid>
                  );
                })
              ) : or?.length > 0 ? (
                or?.map((meal) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={meal?.id + 1}>
                      <RestaurantItem
                        view={"grid"}
                        name={meal?.name}
                        id={meal?._id}
                        image={meal?.images?.profile}
                        deliveryTime={meal?.delivery?.time}
                        rating={meal?.avgRating}
                        serviceFee={meal?.service?.fee}
                        discount={meal?.discount}
                        dataX={meal}
                      />
                    </Grid>
                  );
                })
              ) : (
                <Box
                  textalign={"center"}
                  padding={"4rem"}
                  width={"100%"}
                  sx={{
                    padding: {
                      xs: 4,
                      sm: "4rem",
                    },
                  }}
                >
                  <img src={MenuImage} width={"12%"} alt="no_menu" />
                  <Typography variant="h6" mt={3}>
                    {t("No Restaurant")}
                  </Typography>
                  <Typography variant="body1" color={"GrayText"}>
                    {t("Sorry there are no open restaurants for now")}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Box>

          <Button
            size="large"
            variant="contained"
            className="wobble-hor-bottom"
            disableElevation
            color="secondary"
            onClick={() => navigate("/restaurants")}
            sx={{
              borderRadius: "10px",
              display: "block",
              margin: "0 auto",
              width: "10rem",
              textalign: "center",
            }}
          >
            {t("See More")}
          </Button>
        </Container>
      </Box>
      {/* Categories */}
      <Box sx={{ padding: "5rem 0", position: "relative" }}>
        <Container maxWidth="xl">
          <Typography
            variant="h3"
            className="oswald-categoryCard"
            mb={"2rem"}
            textalign={"center"}
          >
            {t("Top Categories")}
          </Typography>
          <PropertySlider
            key={cc}
            margin={20}
            loop={true}
            autoplay={true}
            autoplaySpeed={500}
            smartSpeed={450}
            nav={true}
            navElement="div"
            navText={[]}
            responsive={{
              1400: { items: "4" },
              1024: { items: "4" },
              550: { items: "2" },
              320: { items: "1" },
            }}
          >
            {CategoriesMapped?.map((el, index) => {
              return (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  gap={el.title.toLowerCase() === "fries" ? 0 : 4}
                  className={"category-card-bg"}
                  key={index}
                  textalign={"center"}
                  onClick={(e) => {
                    setCC(el.title);
                    e.preventDefault();
                    if (el.title.toLocaleLowerCase() === "all")
                      return setDishes(constDishes);
                    setDishes(false);
                    homePageAPI
                      .getDishesByCategory(el.title.toLowerCase())
                      .then((response) => {
                        setDishes(response?.data);
                      })
                      .catch((e) =>
                        dispatch({
                          type: "ERROR",
                          payload: "An Error has occurred",
                        })
                      );
                  }}
                  padding={2}
                  borderRadius={"20px"}
                  sx={el.sx}
                >
                  <img
                    src={el.image}
                    className={el.title === "Pizza" ? "rotate-infinitely" : ""}
                    style={{
                      width: "200px",
                      height: "auto",
                      display: "block",
                      margin: "0 auto",
                    }}
                    alt={`category_${el.id}`}
                  />
                  <Typography
                    variant="h5"
                    className="category-title oswald-categoryCard"
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    {el.title}
                  </Typography>
                  <div
                    className="underline"
                    style={{
                      border: "1px solid black",
                      backgroundColor: "black",
                      height: "2px",
                      width: "20%",
                      marginInline: "center",
                    }}
                  ></div>
                </Box>
              );
            })}
          </PropertySlider>
          <Divider sx={{ marginBlock: 5 }} />
          {!dishes && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
          {dishes && dishes?.length > 0 && (
            <PropertySlider
              items={dishes?.length}
              margin={20}
              loop={true}
              autoPlay={true}
              autoplaySpeed={500}
              smartSpeed={450}
              nav={true}
              navElement="div"
              navText={[]}
              responsive={{
                1400: { items: "4" },
                1024: { items: "4" },
                550: { items: "2" },
                320: { items: "1" },
              }}
            >
              {dishes?.map((el, index) => {
                return (
                  <MealItem
                    key={index}
                    name={el?.title}
                    image={el?.image}
                    price={el?.price}
                    description={el?.description}
                    onClick={() =>
                      navigate(`/restaurants/${el?.restaurant?._id}`)
                    }
                    discount={data?.discount}
                  />
                );
              })}
            </PropertySlider>
          )}
          {dishes?.length <= 0 && (
            <Box
              textalign={"center"}
              padding={"4rem"}
              width={"100%"}
              sx={{
                padding: {
                  xs: 0,
                  sm: "4rem",
                },
              }}
            >
              <img src={MenuImage} width={"12%"} alt="no_menu" />
              <Typography variant="h6" mt={3}>
                {t("No meal")}
              </Typography>
              <Typography variant="body1" color={"GrayText"}>
                {t("Sorry there is no meal")}
              </Typography>
            </Box>
          )}
        </Container>
      </Box>

      <Box bgcolor={"#f7f2e2"} padding={"5rem 0"} display="none">
        <DishGrid></DishGrid>
        <br></br>
        <DishGrid></DishGrid>
      </Box>

      {/* Open restaurant */}
      <Box padding={"2rem 0"} id="restaurantsOpen">
        <Container maxWidth="xl" textalign={"center"}>
          <Typography
            variant="h4"
            sx={{ fontWeight: "600" }}
            mb={"1rem"}
            textalign={"center"}
          >
            {t("Discover New Places")}
          </Typography>

          <Box
            sx={{
              mb: "1rem",
              mt: "2rem",
              display: "block",
              position: "relative",
              marginInline: "auto",
            }}
          >
            <Grid container spacing={4}>
              {isLoading ? (
                [1, 2, 3, 4, 5, 6, 7, 8].map((el) => {
                  return (
                    <Grid
                      key={el + "fmeoirfmio"}
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      sx={{
                        padding: "1 !important",
                      }}
                    >
                      <Skeleton
                        sx={{ height: "22rem", mt: "-5.5rem" }}
                        animation="wave"
                      />
                    </Grid>
                  );
                })
              ) : or?.length > 0 ? (
                or?.map((meal) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={meal?.id + 1}>
                      <RestaurantItem
                        view={"grid"}
                        name={meal?.name}
                        id={meal?._id}
                        image={meal?.images?.profile}
                        deliveryTime={meal?.delivery?.time}
                        rating={meal?.avgRating}
                        serviceFee={meal?.service?.fee}
                        discount={meal?.discount}
                        dataX={meal}
                      />
                    </Grid>
                  );
                })
              ) : (
                <Box
                  textalign={"center"}
                  padding={"4rem"}
                  width={"100%"}
                  sx={{
                    padding: {
                      xs: 4,
                      sm: "4rem",
                    },
                  }}
                >
                  <img src={MenuImage} width={"12%"} alt="no_menu" />
                  <Typography variant="h6" mt={3}>
                    {t("No Restaurant")}
                  </Typography>
                  <Typography variant="body1" color={"GrayText"}>
                    {t("Sorry there are no open restaurants for this category")}{" "}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Box>

          <Button
            size="large"
            variant="contained"
            className="wobble-hor-bottom"
            disableElevation
            color="secondary"
            onClick={() => navigate("/restaurants")}
            sx={{
              borderRadius: "10px",
              display: "block",
              margin: "0 auto",
              width: "10rem",
              textalign: "center",
            }}
          >
            {/* View More */}
            {t("See More")}
          </Button>
        </Container>
      </Box>

      {/* with Discount */}
      <Box padding="1rem 0" id="restaurantsDiscount">
        <Container maxWidth="xl">
          <Typography
            variant="h4"
            sx={{ fontWeight: "600" }}
            mb={"1rem"}
            textalign={"center"}
          >
            {/* With Discount */}
            En Promo
          </Typography>

          <Box sx={{ mb: "2rem", mt: "2rem", display: "block" }}>
            <Grid container spacing={4}>
              {isLoading ? (
                [1, 2, 3, 4, 5, 6, 7, 8].map((el) => {
                  return (
                    <Grid key={el} item xs={12} md={3}>
                      <Skeleton
                        sx={{ height: "22rem", mt: "-5.5rem" }}
                        animation="wave"
                      />
                    </Grid>
                  );
                })
              ) : withDiscount?.length > 0 ? (
                withDiscount?.slice(0, 4)?.map((meal) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={meal?.id}>
                      <RestaurantItem
                        view={"grid"}
                        name={meal?.name}
                        id={meal?._id}
                        image={meal?.images?.profile}
                        deliveryTime={meal?.delivery?.time}
                        rating={meal?.avgRating}
                        serviceFee={meal?.service?.fee}
                        discount={meal?.discount}
                        dataX={meal}
                      />
                    </Grid>
                  );
                })
              ) : (
                <Box textalign={"center"} padding={"4rem"} width={"100%"}>
                  <img src={MenuImage} width={"12%"} alt="no_menu" />
                  <Typography variant="h6" mt={3}>
                    {/* No Restaurants */}
                    Pas de Restaurants
                  </Typography>
                  <Typography variant="body1" color={"GrayText"}>
                    {/* Sorry there are no restaurants with discount{" "} */}
                    Desole, aucun restaurant est en promo actuellement!
                  </Typography>
                </Box>
              )}
            </Grid>
          </Box>

          <Button
            size="large"
            variant="contained"
            className="wobble-hor-bottom"
            disableElevation
            color="secondary"
            onClick={() => navigate("/restaurants")}
            sx={{
              borderRadius: "10px",
              display: "block",
              margin: "0 auto",
              width: "10rem",
              textalign: "center",
            }}
          >
            {/* View More */}
            {t("See More")}
          </Button>
        </Container>
      </Box>

      {/* Dicover New Places */}
      <Box padding="1rem 0" id="restaurantsNew">
        <Container maxWidth="xl">
          <Typography
            variant="h4"
            sx={{ fontWeight: "600" }}
            mb={"2rem"}
            textalign={"center"}
          >
            {t("Popular")}
          </Typography>

          <Box sx={{ mb: "2rem", mt: "2rem", display: "block" }}>
            <Grid container spacing={4}>
              {isLoading ? (
                [1, 2, 3, 4, 5, 6, 7, 8].map((el) => {
                  return (
                    <Grid key={el} item xs={12} md={3}>
                      <Skeleton
                        sx={{ height: "22rem", mt: "-5.5rem" }}
                        animation="wave"
                      />
                    </Grid>
                  );
                })
              ) : data?.length > 0 ? (
                data?.slice(0, 4)?.map((meal) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={meal?.id}>
                      <RestaurantItem
                        view={"list"}
                        name={meal?.name}
                        id={meal?._id}
                        image={meal?.images?.profile}
                        deliveryTime={meal?.delivery?.time}
                        rating={meal?.avgRating}
                        serviceFee={meal?.service?.fee}
                        discount={meal?.discount}
                        dataX={meal}
                      />
                    </Grid>
                  );
                })
              ) : (
                <Box textalign={"center"} padding={"4rem"} width={"100%"}>
                  <img src={MenuImage} width={"12%"} alt="no_menu" />
                  <Typography variant="h6" mt={3}>
                    {t("No Restaurant")}
                  </Typography>
                  <Typography variant="body1" color={"GrayText"}>
                    {t("Sorry there are no open restaurants for this category")}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Box>

          <Button
            size="large"
            variant="contained"
            className="wobble-hor-bottom"
            disableElevation
            color="secondary"
            onClick={() => navigate("/restaurants")}
            sx={{
              borderRadius: "10px",
              display: "block",
              margin: "0 auto",
              width: "10rem",
              textalign: "center",
            }}
          >
            {/* View More */}
            {t("See More")}
          </Button>
        </Container>
      </Box>

      <Box padding="2rem 0" id="restaurantsOpen">
        <Container maxWidth="xl" textalign={"center"}>
          <Typography
            variant="h4"
            sx={{ fontWeight: "600" }}
            mb={"1rem"}
            textalign={"center"}
          >
            {t("All Restaurants")}
          </Typography>

          <Box
            sx={{
              mb: "1rem",
              mt: "2rem",
              display: "block",
              position: "relative",
              marginInline: "auto",
            }}
          >
            <Grid container spacing={4}>
              {isLoading ? (
                [1, 2, 3, 4, 5, 6, 7, 8].map((el) => {
                  return (
                    <Grid
                      key={el + "fmeoirfmio"}
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      sx={{
                        padding: "1 !important",
                      }}
                    >
                      <Skeleton
                        sx={{ height: "22rem", mt: "-5.5rem" }}
                        animation="wave"
                      />
                    </Grid>
                  );
                })
              ) : or?.length > 0 ? (
                or?.map((meal) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={meal?.id + 1}>
                      <RestaurantItem
                        view={"grid"}
                        name={meal?.name}
                        id={meal?._id}
                        image={meal?.images?.profile}
                        deliveryTime={meal?.delivery?.time}
                        rating={meal?.avgRating}
                        serviceFee={meal?.service?.fee}
                        discount={meal?.discount}
                        dataX={meal}
                      />
                    </Grid>
                  );
                })
              ) : (
                <Box
                  textalign={"center"}
                  padding={"4rem"}
                  width={"100%"}
                  sx={{
                    padding: {
                      xs: 4,
                      sm: "4rem",
                    },
                  }}
                >
                  <img src={MenuImage} width={"12%"} alt="no_menu" />
                  <Typography variant="h6" mt={3}>
                  {t("No Restaurant")}
                  </Typography>
                  <Typography variant="body1" color={"GrayText"}>
                    {t("Sorry there are no open restaurants for this category")}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Box>

          <Button
            size="large"
            variant="contained"
            className="wobble-hor-bottom"
            disableElevation
            color="secondary"
            onClick={() => navigate("/restaurants")}
            sx={{
              borderRadius: "10px",
              display: "block",
              margin: "0 auto",
              width: "10rem",
              textalign: "center",
            }}
          >
            {t("See More")}
          </Button>
        </Container>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
        }}
      >
        <FlexBox flex={1} sx={{ bgcolor: "tertiary.main" }}>
          <img
            src={Crispy}
            width={"80%"}
            style={{ position: "absolute", right: "-35%" }}
            alt="clickso_chicken"
            className="pulsate-bck"
          />
          <Typography variant="h6">Crispy Fried</Typography>
          <Typography variant="h3" sx={{ fontWeight: 700 }} color={"paper"}>
            Chicken
          </Typography>
          <Typography variant="body1" my={2}>
            NEW PHENOMENON BURGER TASTE
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: 600, mb: "2rem" }}>
            €8.99
          </Typography>
          <Button
            size="large"
            variant="contained"
            className="wobble-hor-bottom"
            disableElevation
            color="paper"
            sx={{ borderRadius: "10px", color: "#000" }}
          >
            {/* View More */}
            {t("See More")}
          </Button>
        </FlexBox>
        <FlexBox flex={1} sx={{ bgcolor: "secondary.main" }}>
          <img
            src={BgBurger}
            width={"80%"}
            style={{ position: "absolute", right: "-35%" }}
            alt="clickso_burger"
            className="pulsate-bck"
          />
          <Typography variant="h6">Juicy</Typography>
          <Typography variant="h3" sx={{ fontWeight: 700 }} color={"#ffc900"}>
            Burgers
          </Typography>
          <Typography variant="body1" my={2}>
            NEW PHENOMENON BURGER TASTE
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: 600, mb: "2rem" }}>
            €8.99
          </Typography>
          <Button
            size="large"
            variant="contained"
            className="wobble-hor-bottom"
            disableElevation
            color="paper"
            sx={{ borderRadius: "10px", color: "#000" }}
          >
            {/* View More */}
            {t("See More")}
          </Button>
        </FlexBox>
        <FlexBox flex={1} sx={{ bgcolor: "#ffc900" }}>
          <img
            src={Salad}
            width={"100%"}
            style={{ position: "absolute", right: "-40%" }}
            alt="clickso_salad"
            className="pulsate-bck"
          />
          <Typography variant="h6">Fresh</Typography>
          <Typography variant="h3" sx={{ fontWeight: 700 }} color={"secondary"}>
            Salad
          </Typography>
          <Typography variant="body1" my={2}>
            NEW PHENOMENON BURGER TASTE
          </Typography>
          <Typography
            variant="h5"
            color={"text.main"}
            sx={{ fontWeight: 600, mb: "2rem" }}
          >
            €10.99
          </Typography>
          <Button
            size="large"
            variant="contained"
            className="wobble-hor-bottom"
            disableElevation
            color="secondary"
            sx={{ borderRadius: "10px" }}
          >
            {/* View More */}
            {t("See More")}
          </Button>
        </FlexBox>
      </Box>

      {/* Top Restaurants */}
      <Box sx={{ padding: "5rem 0" }}>
        <Container>
          <Grid container spacing={5} justifyContent="center">
            <Grid item sm={9}>
              <Typography variant="h4" mb={3} sx={{ fontWeight: 600 }}>
              {t("New Deals")}
              </Typography>
              <Grid container spacing={3}>
                {newArrivals?.map((newArrival, index) => {
                  return (
                    <Grid item xs={12} md={4} key={index}>
                      <MealItem
                        name={newArrival?.title}
                        price={newArrival?.price}
                        image={newArrival?.image}
                        description={newArrival?.description}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid
              item
              md={3}
              sx={{
                width: "99%",
              }}
            >
              <Box
                bgcolor={"tertiary.main"}
                height={"100%"}
                width={"100%"}
                borderRadius={"15px"}
                sx={{
                  position: "relative",
                  color: "#fff",
                  textalign: "center",
                  overflow: {
                    xs: "hidden",
                  },
                }}
              >
                <Box
                  sx={{
                    display: {
                      xs: "none",
                      md: "block",
                    },
                  }}
                >
                  <img
                    className="register-with-us_image"
                    src={FriedChicken}
                    alt="clickso_chicken"
                  />
                </Box>
                <Box>
                  <img
                    className="register-with-us_image-2"
                    src={Burger}
                    alt="clickso_pizza"
                  />
                </Box>
                <Box
                  sx={{
                    zIndex: 999,
                    padding: {
                      xs: "1rem",
                      md: "0",
                    },
                    position: {
                      xs: "relative",
                      md: "static",
                    },
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography textalign={"center"} pt={10}>
                    Super Delicious
                  </Typography>
                  <Typography
                    variant="h4"
                    textalign={"center"}
                    sx={{
                      fontSize: {
                        xs: "2rem",
                        xl: "3rem",
                      },
                      fontWeight: 700,
                    }}
                  >
                    CHICKEN
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 300,
                      mb: 2,
                      paddingInline: {
                        xs: 0,
                        md: 0,
                      },
                    }}
                    noWrap
                  >
                    Quisque pretium turpis non tempus cursus.
                  </Typography>
                  <Button
                    variant="contained"
                    color="paper"
                    sx={{
                      color: "#000",
                      marginBlock: {
                        xs: 1,
                        md: 0,
                      },
                    }}
                    onClick={() => navigate("/register")}
                  >
                    {t("Sign Up")}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Homepage;
