import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/Header/PageHeader";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  Pagination,
  Radio,
  RadioGroup,
  Rating,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Check, CheckBox, LocationOn, Search, ViewList, ViewModule } from "@mui/icons-material";
import RestaurantItem from "../../../components/Restaurant/Item/RestaurantItem";
import { grey } from "@mui/material/colors";
import {
  homePageAPI,
} from "../../../services/services";
import { useDispatch } from "react-redux";
import { checkBoxLabels, extraFiltersOption } from "../../../constant";
import MenuImage from "../../../assets/menus.png";

const Restaurants = () => {

  const [sort, setSorting] = useState("default");
  const [reset, setReset] = useState(0);
  
  // Search variables
  const [searchName, setSearchName] = useState('');
  const [workingDays, setWD] = useState([]);
  const [searchRating, setSearchRating] = useState([])
  const [extraSearch, setExtraSearch] = useState([])




  const [view, setView] = useState("grid");
  const dispatch = useDispatch();
  
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [constData, setConstData] = useState([]);

  // Pagniation Variables
  const [totalPages, setTotalPages] = useState(0);
  const [pageCount, setPageCount] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);

  const handlePageChange = (event, newValue) => {
    setPage(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {

        const response = await homePageAPI.getRestaurantsWithFilters({
          page,
          limit,
        });
        setData(response?.data);
        setConstData(response?.data);
        setPageCount(response?.totalCount);
        setTotalPages(response?.totalPages)
        setIsLoading(false);
      } catch (error) {
        dispatch({
          type: "ERROR",
          payload: "Error while fetching restaurants",
        });
      }
    };

    fetchData();
  }, [page, dispatch]);

  const filterRestaurantsByName = (e) => {
  };

  const sortBy = ({ label, data }) => {
    const dataReplicate = [...data];
    switch (label) {
      case "rating":
        const sortedR = dataReplicate.sort((a, b) => a.avgRating - b.avgRating);
        setData(sortedR);
        break;
      case "discount":
        const sortedD = dataReplicate.sort(
          (a, b) =>
            a.discount?.discount_percentage - b.discount?.discount_percentage
        );
        setData(sortedD);
        break;
      default:
        setData(data);
    }
  };

  const searchRestaurants = async () => {
    let filters = {}

    
    try {
        filters = {...filters, page, limit}

        if (workingDays.length > 0) {
          filters.day = workingDays.join(',')
        }
        
        const response = await homePageAPI.getRestaurantsWithFilters(filters);
        setData(response?.data);
        setConstData(response?.data);
        setPageCount(response?.totalCount);
        setTotalPages(response?.totalPages)
        setIsLoading(false);
      } catch (error) {
        dispatch({
          type: "ERROR",
          payload: "Error while fetching restaurants",
        });
      }
  }

  return (
    <>
      <PageHeader page={"Restaurants"} type={1} link={"/restaurants"} />
      {/* content */}

      {/* MAIN BOX */}
      <Box padding={"6rem 0"}>
        {/* CONTAINER INSIDE BOX WRAPPING GRID */}
        <Container maxWidth="xl">
          {/* GRID WRAPPING ALL CONTENT INSIDE STATED AS GRID ITEMS */}
          <Grid container columnSpacing={5}>
            {/* GRID ITEM FOR FILTERS */}
            <Grid key={reset} item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                fullWidth
                type="search"
                sx={{ mb: 4 }}
                placeholder="Nom du Restaurant"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                onChange={filterRestaurantsByName}
              />

              <Box
                sx={{
                  borderRadius: "10px",
                  border: "1px solid lightgrey",
                  height: "fit-content",
                  display: "flex",
                  flexDirection: "column",
                  padding: "15px",
                  position: "sticky",
                  top: 10,
                }}
              >
                <FormGroup>
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    sx={{
                      fontWeight: "Bold",
                      textTransform: "uppercase",
                    }}
                  >
                    Jours d'ouverture
                  </FormLabel>
                  {checkBoxLabels.map((label, index) => (
                    <FormControlLabel
                      key={index}
                      onChange={() => {}}
                      control={<Checkbox />}
                      label={label}
                    />
                  ))}
                </FormGroup>
                {/* <Divider light sx={{ marginBlock: "1rem" }} />
                <FormControl>
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    sx={{ fontWeight: "Bold" }}
                  >
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    {deliveryOptions.map((o, index) => (
                      <FormControlLabel
                        key={index}
                        value={o}
                        control={<Radio sx={{}} />}
                        label={o}
                      />
                    ))}
                  </RadioGroup>
                </FormControl> */}
                <Divider light sx={{ marginBlock: "1rem" }} />
                <FormControl>
                  <FormGroup
                    column="true"
                    aria-labelledby="rating"
                    name="row-radio-buttons-group"
                  >
                  <FormLabel id="rating" sx={{ fontWeight: "Bold" }}>
                    {/* RATING */}
                    NOTES
                  </FormLabel>
                  {[5,4,3,2,1].map((label, index) => (
                    <FormControlLabel
                      key={index}
                      onChange={() => {}}
                      control={<Checkbox />}
                      label={
                        <Rating name="read-only" value={label} readOnly />
                      }
                    />
                  ))}
                  </FormGroup>
                </FormControl>
                <Divider sx={{ marginBlock: "1rem" }}></Divider>{" "}
                <FormGroup>
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    sx={{
                      fontWeight: "Bold",
                      textTransform: "uppercase",
                    }}
                  >
                    Extra Filtres
                  </FormLabel>
                  {extraFiltersOption.map((label, index) => (
                    <FormControlLabel
                      key={index}
                      onChange={() => {
                      }}
                      control={<Checkbox />}
                      label={label}
                    />
                  ))}
                </FormGroup>
                <Button
                  type="button"
                  onClick={() => {
                    setReset(reset + 1);
                    setData(constData);
                  }}
                >
                  Rechercher
                </Button>
                <Button
                  type="reset"
                  onClick={() => {
                    setReset(reset + 1);
                    setData(constData);
                  }}
                >
                  Reinitialiser
                </Button>
              </Box>
            </Grid>

            <Grid item sm={8}>
              <Box
                direction={"row"}
                gap={"2rem"}
                sx={{ mb: "2rem", maxWidth: "100%" }}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                {pageCount && (
                  <Typography
                    sx={{
                      marginBlock: {
                        xs: "10px",
                        md: "0",
                      },
                    }}
                  >
                    {/* Showing {data?.length} results */}
                  </Typography>
                )}
                <Stack direction={"row"} gap={"1rem"} alignItems={"center"}>
                  <IconButton
                    sx={{
                      bgcolor: view === "list" ? "primary.main" : grey[200],
                      borderRadius: "5px",
                      color: view === "list" ? "paper.main" : null,
                      "&:hover": {
                        backgroundColor: "primary.main", // Remove background color on hover
                        color: "#FFF",
                      },
                    }}
                    onClick={() => setView("list")}
                  >
                    <ViewList />
                  </IconButton>
                  <IconButton
                    sx={{
                      bgcolor: view === "grid" ? "primary.main" : grey[200],
                      borderRadius: "5px",
                      color: view === "grid" ? "paper.main" : null,
                      "&:hover": {
                        backgroundColor: "primary.main", // Remove background color on hover
                        color: "#FFF",
                      },
                    }}
                    onClick={() => setView("grid")}
                  >
                    <ViewModule />
                  </IconButton>
                  <TextField
                    select
                    size="small"
                    variant="outlined"
                    value={sort}
                    onChange={(e) => {
                      setSorting(e.target.value);
                      sortBy({ label: e.target.value, data: constData });
                    }}
                    sx={{ width: "8rem" }}
                  >
                    <MenuItem value={"default"}>Default</MenuItem>
                    <MenuItem value={"rating"}>By Rating</MenuItem>
                    <MenuItem value={"discount"}>By Discount</MenuItem>
                  </TextField>
                </Stack>
              </Box>

              {/* GRID CONTAINER FOR RESTAURANT ITEMS */}
              <Grid container spacing={3}>
                {isLoading ? (
                  [1, 2, 3, 4, 5, 6, 7, 8].map((el) => {
                    return (
                      <Grid key={el} item xs={12} md={4}>
                        <Skeleton
                          sx={{ height: "22rem", mt: "-5.5rem" }}
                          animation="wave"
                        />
                      </Grid>
                    );
                  })
                ) : data?.length > 0 ? (
                  data?.map((meal) => {
                    return (
                      <Grid item xs={12} md={4} key={meal?.id}>
                        <RestaurantItem
                          view={view}
                          name={meal?.name}
                          id={meal?._id}
                          image={meal?.images?.profile}
                          deliveryTime={meal?.delivery?.time}
                          rating={meal?.avgRating}
                          serviceFee={meal?.service?.fee}
                          discount={meal?.discount}
                          dataX={meal}
                        />
                      </Grid>
                    );
                  })
                ) : (
                  <Box textAlign={"center"} padding={"4rem"} width={"100%"}>
                    <img src={MenuImage} width={"12%"} alt="no_menu" />
                    <Typography variant="h6" mt={3}>
                      No Restaurants
                    </Typography>
                    <Typography variant="body1" color={"GrayText"}>
                      Sorry there are no restaurants for this search{" "}
                    </Typography>
                  </Box>
                )}
                {/* Pagination */}
                <Box
                  my={"2rem"}
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pagination
                    size="medium"
                    color="secondary"
                    shape="rounded"
                    variant="outlined"
                    defaultPage={1}
                    count={totalPages}
                    onChange={handlePageChange}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Restaurants;
