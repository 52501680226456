import React from 'react';
import { Container, Typography, Box, Paper, Button, Grid, Card, CardContent, CardMedia, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import { Fade } from '@mui/material';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ContactMailIcon from '@mui/icons-material/ContactMail';

// IMAGES
import RestaurantImage from "../../../assets/toa-heftiba-6bKpHAun4d8-unsplash.jpg"
import FoodImage from "../../../assets/h1_burger-1.png"

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
  backgroundColor: '#f9f9f9',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(4),
  backgroundColor: '#ff7043',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#ff8a65',
  },
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  marginTop: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const ContactContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(6),
  backgroundColor: '#e3f2fd',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const AboutUs = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Container maxWidth="md">
      <Fade in timeout={1000}>
        <StyledPaper elevation={3}>
          <Box textAlign="center" mb={4}>
            <Typography variant="h4" component="h1" gutterBottom>
              À propos de Clickso.fr
            </Typography>
          </Box>
          <Typography variant="body1" paragraph>
            Bienvenue sur Clickso.fr, votre plateforme de référence pour connecter les restaurants avec leurs clients de manière simple et efficace.
            Nous croyons que commander de la nourriture devrait être aussi agréable que de la déguster, et c'est pourquoi nous avons développé
            cette plateforme pour simplifier le processus à la fois pour les restaurateurs et leurs clients.
          </Typography>
          <Typography variant="body1" paragraph>
            Chez Clickso.fr, nous donnons aux restaurants les moyens de présenter leurs menus et leurs offres à un public plus large.
            Que vous gériez un petit café local ou une grande chaîne de restaurants, Clickso.fr vous fournit les outils pour gérer votre
            présence en ligne et atteindre plus de clients. Notre interface conviviale permet aux restaurants de s'inscrire, de télécharger
            leurs menus et de commencer à recevoir des commandes en un rien de temps.
          </Typography>

          {/* Cards Section */}
          <Grid container spacing={4} mt={4}>
            <Grid item xs={12} sm={6}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image={RestaurantImage} // Replace with actual image URLs
                  alt="Restaurant"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Gérer votre restaurant
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Présentez vos menus, gérez vos commandes et connectez-vous avec vos clients facilement.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image={FoodImage} // Replace with actual image URLs
                  alt="Food"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Offrir une meilleure expérience
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Faites de la commande de nourriture une expérience fluide et agréable pour vos clients.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <ImageContainer>
            <RestaurantIcon sx={{ fontSize: isSmallScreen ? 100 : 150, color: '#ff7043' }} />
            <FastfoodIcon sx={{ fontSize: isSmallScreen ? 100 : 150, color: '#ff7043' }} />
          </ImageContainer>

          <Box textAlign="center" mt={4}>
            <Typography variant="h6" component="h2">
              Merci de choisir Clickso.fr !
            </Typography>
          </Box>
          <Box textAlign="center">
            <StyledButton variant="contained" size="large">
              Rejoignez-nous dès maintenant
            </StyledButton>
          </Box>
        </StyledPaper>
      </Fade>

      {/* Contact Section */}
      <Fade in timeout={1500} sx={{mb: 10}}>
        <ContactContainer elevation={3}>
          <Box textAlign="center" mb={4}>
            <Typography variant="h5" component="h2" gutterBottom>
              Contactez-nous
            </Typography>
            <Typography variant="body1" paragraph>
              Pour toute question ou assistance, n'hésitez pas à nous contacter. Nous sommes là pour vous aider !
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box textAlign="center">
                <LocationOnIcon sx={{ fontSize: 60, color: '#ff7043' }} />
                <Typography variant="h6" component="p">
                  Notre adresse
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Roubaix, France
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box textAlign="center">
                <ContactMailIcon sx={{ fontSize: 60, color: '#ff7043' }} />
                <Typography variant="h6" component="p">
                  Email
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  support@clickso.fr
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box mt={4}>
            <iframe
              title="Roubaix Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2520.460919139152!2d3.163677315848434!3d50.691334279507116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c328eac2a104d9%3A0x52ff269d217c51ee!2sRoubaix%2C%20France!5e0!3m2!1sen!2sus!4v1631545810731!5m2!1sen!2sus"
              width="100%"
              height="300"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </Box>
        </ContactContainer>
      </Fade>
    </Container>
  );
}

export default AboutUs;
