import React, { useEffect, useMemo, useState } from "react";
import PageHeader from "../../components/Header/PageHeader";
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Grid,
  IconButton,
  Pagination,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { Receipt, ViewList, ViewModule } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import {
  getOrdersFiltered,
  getOrdersWithPaginationAndFilters,
  userAccountAPI,
} from "../../services/services";
import { useNavigate } from "react-router-dom";
import OrderItem from "../../components/Order/Item/OrderItem";
import OrderDetails from "../../components/Order/Details/OrderDetails";
import MenuImage from "../../assets/menus.png";
import { useTranslation } from "react-i18next";


const TabsElement = [
  {
    label: "ordered",
    variant: "outlined",
  },
  {
    label: "ongoing",
    variant: "outlined",
  },

  {
    label: "completed",
    variant: "outlined",
  },
  {
    label: "cancelled",
    variant: "outlined",
  },
  {
    label: "delivered",
    variant: "outlined",
  },
  {
    label: "all",
    variant: "outlined",
  },
];
const Orders = () => {
  const [view, setView] = useState("list");
  const queryParams = new URLSearchParams(window.location.search);
  const [query, setQueryX] = useState(queryParams.get("q"));
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [order, setOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation()

  const TabsElementMapped = useMemo(
    () =>
      TabsElement.map((el) => {
        return {
          label: el.label,
          variant: el.label === query ? "contained" : "outlined",
        };
      }),
    [query]
  );

  const handlePageChange = (event, newValue) => {
    setPage(newValue);
  };

  useEffect(() => {
    setIsLoading(true);
    setOrder(null);
    if (!query) return navigate("/");
    // window.scrollTo({ top: 0, behavior: "smooth" });
    // getOrdersWithPaginationAndFilters(page, 6, query)
    //   .then((response) =>
    //     setOrders(() => {
    //       setTotalPages(response?.numberOfPages);
    //       setIsLoading(false);
    //       return response.data;
    //     })
    //   )
    //   .catch((e) => {
    //     return;
    //   });

    userAccountAPI.getOrders().then(res=>{
      
      setOrders(res?.data)
      setIsLoading(false);
    }).catch(e=>alert(e?.message))


  }, [query, page, navigate]);

  return (
    <>
      <PageHeader
        page={t("Navbar.ProfileMenu.My Orders")}
        type={1}
        link={"/account/orders?q=ordered"}
      />
      <Box padding={"6rem 0"}>
        <Container maxWidth="xl">
          <Grid container columnSpacing={5}>
            <Grid item xs={12} md={8}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                gap={"2rem"}
                sx={{ mb: "2rem" }}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <ButtonGroup
                  variant="text"
                  sx={{
                    flexWrap: "wrap",
                    flexDirection: {
                      xs: "row",
                      sm: "row",
                    },
                  }}
                >
                  {TabsElementMapped.map((el, index) => (
                    <Button
                      key={index}
                      type="button"
                      onClick={(e) =>
                        setQueryX(() => {
                          queryParams.set("q", el.label);
                          // Update the URL without triggering a re-render
                          const newUrl = `${
                            window.location.pathname
                          }?${queryParams.toString()}`;
                          window.history.replaceState(null, "", newUrl);
                          return queryParams.get("q");
                        })
                      }
                      variant={el.variant}
                      sx={{ textTransform: "capitalize" }}
                      disableElevation
                    >
                      {el.label}
                    </Button>
                  ))}
                </ButtonGroup>
                <Stack
                  direction={{ xs: "row", sm: "column", md: "row" }}
                  gap={"1rem"}
                  alignItems={"center"}
                  sx={{
                    display: {
                      xs: "none",
                      sm: "flex",
                    },
                  }}
                >
                  <IconButton
                    sx={{
                      bgcolor: view === "list" ? "primary.main" : grey[200],
                      borderRadius: "5px",
                      color: view === "list" ? "paper.main" : null,
                      "&:hover": {
                        backgroundColor: "primary.main", // Remove background color on hover
                        color: "#FFF",
                      },
                    }}
                    onClick={() => setView("list")}
                  >
                    <ViewList />
                  </IconButton>
                  <IconButton
                    sx={{
                      "&:hover": {
                        backgroundColor: "primary.main", // Remove background color on hover
                        color: "#FFF",
                      },
                      bgcolor: view === "grid" ? "primary.main" : grey[200],
                      borderRadius: "5px",
                      color: view === "grid" ? "paper.main" : null,
                    }}
                    onClick={() => setView("grid")}
                  >
                    <ViewModule />
                  </IconButton>
                </Stack>
              </Stack>

              <Grid container spacing={3}>
                {!isLoading ? (
                  orders.length > 0 ? (
                    orders.map((order, index) => {
                      return (
                        <Grid
                          item
                          sm={view === "list" ? 6 : 4}
                          key={index}
                          sx={{
                            width: "100%",
                          }}
                        >
                          {/* <RestaurantItem view={view} /> */}
                          <OrderItem
                            data={order}
                            onClick={() => setOrder(order)}
                          />
                        </Grid>
                      );
                    })
                  ) : (
                    <>
                      <Box textAlign={"center"} padding={"4rem"} width={"100%"}>
                        <img src={MenuImage} width={"12%"} alt="no_menu" />
                        <Typography variant="h6" mt={3}>
                          {t("OrderPage.No Orders found")}
                        </Typography>
                      </Box>
                    </>
                  )
                ) : (
                  [1, 2, 3, 4, 5].map((el) => {
                    return (
                      <Grid key={el} item xs={12} sm={6} md={4}>
                        <Skeleton
                          sx={{ height: "22rem", mt: "-5.5rem" }}
                          animation="wave"
                        />
                      </Grid>
                    );
                  })
                )}
                {/* Pagination */}
                <Box
                  my={"2rem"}
                  sx={{
                    width: "100%",
                    paddingInline: {
                      xs: "2rem",
                      sm: "0",
                    },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pagination
                    size="medium"
                    color="secondary"
                    shape="rounded"
                    variant="outlined"
                    count={totalPages}
                    onChange={handlePageChange}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box
                height={"100vh"}
                overflowX={"hidden"}
                overflowY={"scroll"}
                sx={{
                  padding: 3,
                  borderRadius: "15px",
                  border: "1px solid lightgrey",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "sticky",
                  top: 20,
                }}
              >
                {" "}
                {order ? (
                  <OrderDetails data={order} />
                ) : (
                  <>
                    <Receipt sx={{ fontSize: "10rem", color: grey[300] }} />
                    <Typography color={"GrayText"} mt={2}>
                      {t("OrderPage.Select an order to preview")}
                    </Typography>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Orders;
