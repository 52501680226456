import React, { useState } from "react";
import PageHeader from "../../components/Header/PageHeader";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import {
  CameraAlt,
  Fastfood,
  Favorite,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  signoutUser,
  editProfile,
  updateSettingsX,
  userAccountAPI,
} from "../../services/services";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";

const InputField = styled(TextField)({
  marginBottom: "1.2rem",
});

const InputFile = styled("input")({
  display: "none",
});

//TODO Revamp the form

const Profile = (props) => {
  const { signoutUser } = props;
  const { t } = useTranslation()
  const currentUser = useSelector((state) => state.currentUser);
  const { favorites, orders } = useSelector((state) => state.resto);
  const [username, setUsername] = useState(currentUser?.username);
  const [phone, setUserPhone] = useState(currentUser?.phone?.value);
  const [passwordInfo, setPasswordInfo] = useState("");
  const [firstName, setFN] = useState(currentUser?.firstname);
  const [lastName, setLN] = useState(currentUser?.lastname);
  const [loading, setLoading] = useState(false);
  const [passwordUpdating, setPU] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(
    currentUser?.profilePic
  );
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const dispatch = useDispatch();

  const resetInfo = () => {
    setUsername(currentUser?.username);
    setUserPhone(currentUser?.phone?.value);
    setFN(currentUser?.firstname);
    setLN(currentUser?.lastname);
  };

  const handlePasswordInfo = (event) => {
    setPasswordInfo({
      ...passwordInfo,
      [event.target.name]: event.target.value,
    });
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);

    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const updateProfile = async () => {
    setLoading(true);
    if (firstName?.length <= 0)
      return dispatch({ type: "ERROR", payload: "First Name required" });
    if (lastName?.length <= 0)
      return dispatch({ type: "ERROR", payload: "Last Name required" });
    // const formData = new FormData();
    // formData.append("firstname", firstName);
    // formData.append("lastname", lastName);
    // formData.append("image", selectedImage);

    const formData = {
      firstName,
      lastName
    }

    try {
      const response = await userAccountAPI.updateProfile(formData);
      dispatch({ type: "LOGIN_USER", payload: response?.data });
      dispatch({ type: "SUCCESS", payload: "Profile updated successfully" });
    } catch (e) {
      alert(e)
      dispatch({ type: "ERROR", payload: "Could not update profile" });
    } finally {
      setLoading(false);
    }
  };

  const updateProfilePicture = async (e) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);

    try {
      const response = await userAccountAPI.updateProfilePic(formData);
      dispatch({ type: "LOGIN_USER", payload: response?.data });
      dispatch({ type: "SUCCESS", payload: "Profile Pic updated successfully" });
    } catch (e) {
      alert(e)
      dispatch({ type: "ERROR", payload: "Could not update profile pic" });
    } finally {
      setLoading(false);
    }
  }

  const onUpdateSettings = async (event) => {
    event.preventDefault();
    setPU(true);
    if (!passwordInfo || passwordInfo.newPassword.length <= 0)
      return dispatch({ type: "ERROR", payload: "Passwords required" });
    if (!passwordInfo || passwordInfo.currentPassword.length <= 0)
      return dispatch({ type: "ERROR", payload: "Passwords required" });
    if (!passwordInfo || passwordInfo.confirmPassword.length <= 0)
      return dispatch({ type: "ERROR", payload: "Passwords required" });
    if (passwordInfo.newPassword !== passwordInfo.confirmPassword)
      return dispatch({ type: "ERROR", payload: `Passwords don't match` });

    try {
      const response = await userAccountAPI.updatePassword({
        oldPassword: passwordInfo?.currentPassword,
        newPassword: passwordInfo?.newPassword
      });
      if (response.success) {
        throw new Error(response.message);
      }
      dispatch({
        type: "SUCCESS",
        payload: "Password updated successfully, Login again",
      });
      setPasswordInfo({currentPassword: '', newPassword: ''});
    } catch (e) {
      dispatch({
        type: "ERROR",
        payload: "Could not update password, Password mismatched or invalid",
      });
    } finally {
      setPU(false);
    }
  };


  return (
    <>
      <PageHeader page={t("Navbar.ProfileMenu.My Profile")} type={1} link={"/account/me"} />
      <Box padding={"6rem 0"}>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12} sm={4}>
              <Card
                elevation={0}
                variant="outlined"
                sx={{
                  border: "none",
                  borderRadius: "15px",
                  boxShadow: "0 2px 10px rgba(0,0,0,12%)",
                  position: "sticky",
                  top: 20,
                }}
              >
                <CardContent sx={{ padding: "2rem" }}>
                  <Stack
                    position={"relative"}
                    direction={"column"}
                    alignItems={"center"}
                  >
                    <Avatar
                      alt="Preview"
                      src={previewImage}
                      sx={{ width: "8rem", height: "8rem" }}
                    />
                    <InputFile
                      accept="image/*"
                      id="image-upload"
                      type="file"
                      onChange={(e)=>{
                        handleFileSelect(e)
                        updateProfilePicture(e)
                      }}
                    />
                    <IconButton
                      sx={{
                        position: "absolute",
                        bottom: 5,
                        right: "28%",
                        bgcolor: "primary.main",
                        color: "#fff",
                      }}
                    >
                      <label htmlFor="image-upload">
                        <CameraAlt fontSize="small" />
                      </label>
                    </IconButton>
                  </Stack>
                  <Typography mt={3} variant="h6" textAlign={"center"}>
                    {currentUser?.username}
                  </Typography>
                  <Typography
                    variant="body2"
                    textAlign={"center"}
                    noWrap
                    color={"GrayText"}
                  >
                    {currentUser?.email?.value}
                  </Typography>
                  <Divider sx={{ my: "1rem" }} />
                  <Box
                    sx={{
                      display: "flex",
                      gap: "1rem",
                      justifyContent: "center",
                    }}
                  >
                    <Stack direction={"row"} alignItems={"center"} gap={"10px"}>
                      <Tooltip title="Orders" arrow>
                        <Fastfood sx={{}} />
                      </Tooltip>
                      <Typography variant="body1" color={"GrayText"}>
                        {orders.length}
                      </Typography>
                    </Stack>
                    <Divider flexItem orientation="vertical" />
                    <Stack direction={"row"} alignItems={"center"} gap={"10px"}>
                      <Tooltip title="Favorites" arrow>
                        <Favorite
                          sx={{
                            color: favorites.length > 0 ? "red" : "black",
                          }}
                        />
                      </Tooltip>
                      <Typography variant="body1" color={"GrayText"}>
                        {favorites.length}
                      </Typography>
                    </Stack>
                  </Box>
                  <Divider sx={{ my: "1rem" }} />
                  <Button
                    variant="contained"
                    onClick={() => signoutUser()}
                    color="primary"
                    sx={{
                      textTransform: "none",
                      display: "block",
                      margin: "0 auto",
                    }}
                  >
                    {t("Sign Out")}
                  </Button>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Card
                elevation={0}
                variant="outlined"
                sx={{ borderRadius: "15px" }}
              >
                <CardHeader
                  sx={{ bgcolor: grey[200] }}
                  title={
                    <Typography
                      mt={0}
                      variant="h6"
                      textAlign={"left"}
                      sx={{ fontWeight: 500, ml: "1rem" }}
                    >
                      {t("Navbar.ProfileMenu.My Profile")}
                    </Typography>
                  }
                />
                <Divider />
                <CardContent sx={{ padding: "2rem" }}>
                  <InputField
                    label="Email"
                    value={currentUser?.email}
                    disabled={true}
                    fullWidth
                    variant="outlined"
                  />
                  <InputField
                    label="Username"
                    value={username}
                    disabled
                    fullWidth
                    variant="outlined"
                  />
                  <InputField
                    label="Phone"
                    value={phone}
                    disabled
                    fullWidth
                    variant="outlined"
                  />
                  <InputField
                    label={t("Form.First Name")}
                    value={firstName}
                    onChange={(e) => setFN(e.target.value)}
                    fullWidth
                    variant="outlined"
                  />
                  <InputField
                    label={t("Form.Last Name")}
                    value={lastName}
                    onChange={(e) => setLN(e.target.value)}
                    fullWidth
                    variant="outlined"
                  />
                  <Box
                    display={"flex"}
                    flexWrap={"wrap"}
                    gap={"1rem"}
                    justifyContent={"flex-start"}
                  >
                    <LoadingButton
                      variant="contained"
                      loading={loading}
                      color="primary"
                      sx={{ textTransform: "none" }}
                      onClick={updateProfile}
                    >
                      {t("Form.Update")}
                    </LoadingButton>
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: "none",
                        backgroundColor: "orange",
                      }}
                      onClick={resetInfo}
                    >
                      {t("Form.Reset")}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
              <Card
                elevation={0}
                variant="outlined"
                sx={{ borderRadius: "15px", mt: "2rem" }}
              >
                <CardHeader
                  sx={{ bgcolor: grey[200] }}
                  title={
                    <Typography
                      mt={0}
                      variant="h6"
                      textAlign={"left"}
                      sx={{ fontWeight: 500, ml: "1rem" }}
                    >
                      {t("Settings")}
                    </Typography>
                  }
                />
                <Divider />
                <CardContent sx={{ padding: "2rem" }}>
                  <InputField
                    label={t("Form.Password") + ` (${t("Current")})`  }
                    name="currentPassword"
                    type={show ? "text" : "password"}
                    onChange={handlePasswordInfo}
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {show ? (
                            <IconButton onClick={() => setShow(false)}>
                              <Visibility
                                fontSize="small"
                                sx={{ color: grey[400] }}
                              />
                            </IconButton>
                          ) : (
                            <IconButton onClick={() => setShow(true)}>
                              <VisibilityOff
                                fontSize="small"
                                sx={{ color: grey[400] }}
                              />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <InputField
                    label={t("New") + ` ${t("Form.Password")}`}
                    name="newPassword"
                    type={show1 ? "text" : "password"}
                    onChange={handlePasswordInfo}
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {show1 ? (
                            <IconButton onClick={() => setShow1(false)}>
                              <Visibility
                                fontSize="small"
                                sx={{ color: grey[400] }}
                              />
                            </IconButton>
                          ) : (
                            <IconButton onClick={() => setShow1(true)}>
                              <VisibilityOff
                                fontSize="small"
                                sx={{ color: grey[400] }}
                              />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <InputField
                    label={t("Confirm") + ` ${t("Form.Password")}`}
                    name="confirmPassword"
                    type={show2 ? "text" : "password"}
                    onChange={handlePasswordInfo}
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {show2 ? (
                            <IconButton onClick={() => setShow2(false)}>
                              <Visibility
                                fontSize="small"
                                sx={{ color: grey[400] }}
                              />
                            </IconButton>
                          ) : (
                            <IconButton onClick={() => setShow2(true)}>
                              <VisibilityOff
                                fontSize="small"
                                sx={{ color: grey[400] }}
                              />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <LoadingButton
                    variant="contained"
                    loading={passwordUpdating}
                    color="primary"
                    onClick={onUpdateSettings}
                    sx={{ textTransform: "none" }}
                  >
                    {t("Form.Update")}
                  </LoadingButton>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

const mapStateToprops = (state) => {
  return state;
};

export default connect(mapStateToprops, { signoutUser })(Profile);
