import React, { useEffect } from "react";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";

const PublicRoute = (props) => {
  const path = useLocation().pathname;
  const { items } = useSelector((state) => state.cart);
  const { authRequest, currentUser } = props;

  if (currentUser) {
    if (path === "/login" || path === "/register") {
      return <Navigate to={"/"} replace={true} />;
    }
  }
  if (path === "/checkout" && items?.length === 0) {
    return <Navigate to={"/"} replace={true} />;
  }

  return (
    <>
      <Navbar />
      <Outlet />

      {path === "/login" || path === "/register" ? null : <Footer />}
    </>
  );
};

export default PublicRoute;
