import { Adb, Shop } from "@mui/icons-material";
import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import Phone from "../../assets/phone.png";
import Pizza from "../../assets/h7_burger.png";
import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <>
      {/* Download APPS */}
      <Box bgcolor={"#f7f2e2"} padding={"3rem 0"}>
        <Container maxWidth="lg">
          <Grid
            container
            spacing={4}
            sx={{
              margin: "0",
              width: "100%",
            }}
          >
            <Grid
              item
              sm={4}
              sx={{
                padding: "1rem !important",
              }}
            >
              <Box sx={{ position: "relative" }}>
                <img src={Phone} width={"80%"} alt="clickso_phone" />
                <img
                  src={Pizza}
                  style={{
                    width: "80%",
                    position: "absolute",
                    bottom: "-5%",
                    left: "-30%",
                  }}
                  alt="clickso_burger"
                />
              </Box>
            </Grid>
            <Grid
              item
              sm={8}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                padding: "1rem !important",
              }}
            >
              <Typography variant="h4" mb={3} sx={{ fontWeight: 600 }}>
                {t("Download our Mobile App")}{" "}
              </Typography>
              <Typography color={"GrayText"} mb={4}>
                {" "}
                {t("Download Message")}
              </Typography>
              <Stack
                display={"block"}
                direction={"row"}
                spacing={5}
                sx={{
                  width: "fit-content",
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    textTransform: "none",
                    color: "#fff",
                    padding: "1rem 1.5rem",
                  }}
                >
                  <Stack direction={"row"} gap={2} alignItems={"center"}>
                    <Shop fontSize="large" />
                    <span>
                      <Typography variant="body2" textAlign={"left"} sx={{color: "white"}}>
                        For Users
                      </Typography>
                      <Typography variant="h5" mt={1}>
                        Google Play
                      </Typography>
                    </span>
                  </Stack>
                </Button>
                <Button
                  variant="contained"
                  color="tertiary"
                  sx={{
                    textTransform: "none",
                    color: "#fff",
                    padding: "1rem 1.5rem",
                  }}
                >
                  <Stack direction={"row"} gap={2} alignItems={"center"}>
                    <Adb fontSize="large" />
                    <span>
                      <Typography variant="body2" textAlign={"left"} sx={{color: "white"}}>
                        For Restaurants
                      </Typography>
                      <Typography variant="h5" mt={1}>
                        Google Play
                      </Typography>
                    </span>
                  </Stack>
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box bgcolor={grey[900]} height={"20rem"}></Box>
      <Box bgcolor={"tertiary.main"} padding={"1rem"} />
    </>
  );
};

export default Footer;
