import { countryPhoneCodes, emailPattern } from "../constant/index";

let helpers = {}; // Containers for our extra functions


// TODO
// PROVIDE AN OPTIONAL FIELD SO THAT IT DOES NOT COUNT EMPTY STRING AS AN ERROR

helpers.validatePhoneNumber = (phoneNumber) => {
  // Regular expression for validating phone number formats
  let phoneRegex = /^(\(?\d{3}\)?[-.\s]?|\d{3}[-.\s]?)\d{3}[-.\s]?\d{3,4}$/;

  // Test the input phone number against the regular expression
  return phoneRegex.test(phoneNumber) 
};

helpers.validateUsername = (username) => {
  // Regular expression for validating phone
  let usernameRegex = /^[a-zA-Z0-9]+$/g;

  // Test the username against the regular expression
  return usernameRegex.test(username) && username.length > 0
};

helpers.validateFullName = (fullname) => {
  // Regular expression for validating phone
  const fullnameRegex = /^[a-zA-Z]+( [a-zA-Z]+)*$/;

  // Test the full name against the regular expression
  return fullnameRegex.test(fullname) && fullname.length > 0
};

helpers.validatePrice = (price) => {
  // Regular expression for validating price
  const priceRegex = /^\d+(\.\d+)?$/;

  return priceRegex.test(price) || price === "";
};

helpers.validateEmail = (email) => {
  return emailPattern.test(email);
};

helpers.validateForm = (form) => {
  for (const [key, value] of Object.entries(form)) {
    if(value.hasError) return false
  }
  return true
}

helpers.validateWorkingHours = (hours) => {

  const patternX = /^\d{2}\.\d{2} - \d{2}\.\d{2}$/;

  return patternX.test(hours)
}

helpers.phoneUnwind = (phoneNumber) => {
  // Check the phone's country code from the array
  const selectedCpc = countryPhoneCodes.filter((cpc) => phoneNumber?.includes(`+${cpc?.code}`))
  // Selected code default to France (33) if no code is found
  const selectedCode = selectedCpc.length > 0 ? selectedCpc[0]?.code : '33'

  // Check whether the number starts with the selectedCode and split it accordingly
  const phoneNumberX = phoneNumber?.startsWith(`+${selectedCode}`) && phoneNumber.split(`+${selectedCode}`)?.length > 1 ? phoneNumber.split(`+${selectedCode}`)[1] : phoneNumber
  return [`+${selectedCode}`, phoneNumberX]
}


helpers.validatePassword = (password) => {
  return !!password.length
};

export default helpers;
