import React from "react";
import { Snackbar, Alert, Slide, Typography, Button, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, connect } from "react-redux";

const Transition = (props) => {
  return <Slide {...props} direction="down" />;
};

const Modal = (props) => {
  
  const { modal } = props;
  const dispatch = useDispatch();
  const open = Boolean(modal);

  console.log(modal)


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({ type: "RESET_MODAL" });
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={4000}
        TransitionComponent={Transition}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message={modal.message}
        onClose={handleClose}
        action={action}
      >
        <Alert
          variant="filled"
          onClose={handleClose}
          severity={modal.status}
          sx={{
            width: "100%",
            bgcolor: modal.status === "success" ? "tertiary.main" : null,
          }}
        >
          <Typography id="modal-message" variant="body2" sx={{ fontFamily: "Poppins", color: 'white' }}>
            {modal?.message}
          </Typography>
        </Alert>
      </Snackbar>
    </div>
  );
};

const mapStateToprops = (state) => {
  return state;
};

export default connect(mapStateToprops, {})(Modal);
