import axios from "axios";
import CryptoJS from "crypto-js";
import { requestToApi } from "./requestHandler";
import socket from "./socket";
import ForgotPassword from "../layout/auth/ForgotPassword";

const secretKey =
  "N4YgYO2h+x8oTuGG8eTNHusGot7EE0J9bTm5vTAL5/4PSJNUVd+slv/vLWlBEbsi";

axios.defaults.withCredentials = true;

export const base = axios.create({
  baseURL: process.env.REACT_APP_URL,
  // baseURL: "https://06cc93611ed4e6966d333618016fc90b.serveo.net",
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": "en-US,en;q=0.9",
  },
});

export const baseFormData = axios.create({
  baseURL: process.env.REACT_APP_URL,
  // baseURL: "https://5221-154-160-20-207.ngrok-free.app",
  headers: {
    "Content-Type": "multipart/form-data",
    "Accept-Language": "en-US,en;q=0.9",
  },
});

export const encryptData = (data) => {
  const utf8Data = encodeURIComponent(data);
  const encryptedData = CryptoJS.AES.encrypt(utf8Data, secretKey).toString();
  return encryptedData;
};

export const decryptData = (encryptedData) => {
  const decryptedData = CryptoJS.AES.decrypt(encryptedData, secretKey).toString(
    CryptoJS.enc.Utf8
  );
  const originalData = decodeURIComponent(decryptedData);
  return originalData;
};

export const userAccountAPI = {
  register: (data) => requestToApi("/user", 'POST', data),
  login: (data) => requestToApi("/user/login", 'POST', data),
  preLogin: (data) => requestToApi("/user/pre/login", 'POST', data),
  updateProfile: (data) => requestToApi("/user/profile",'PUT', data),
  updateProfilePic: (data) => requestToApi("/user/profile/picture",'PUT', data),
  updatePassword: (data) => requestToApi("/user/password/update",'PUT', data),
  forgotPassword: (data) => requestToApi("/user/password/forgot",'POST', data),
  resetPassword: (token, data) => requestToApi(`/user/password/reset/${token}`,'POST', data),

  validateOtp: (data) => requestToApi("/user/otp/validate", 'POST', data),
  sendOtp: (data) => requestToApi("/user/otp/send", 'POST', data),

  initiatePayment: (data) => requestToApi("/payment/initiate", 'POST', data),

  placeOrder: (data) => requestToApi('/order', 'POST', data),
  getOrders: () => requestToApi('/order/client/self', 'GET'),

  favoriteOp: (data) => requestToApi("/user/favorites", "PUT", data),
  getFav: () => requestToApi("/user/favorites", 'GET'),

  submitReview: (restaurant, data) => requestToApi(`/reviews/restaurant/${restaurant}`, 'POST', data),
  getReviewUserSelf: () => requestToApi('/reviews/user/self', "GET"),
}

export const homePageAPI = {
  getRestaurants: (data) => requestToApi("/restaurant", 'GET'),
  getRestaurantsWithFilters: (params) => requestToApi("/restaurant/search/q", 'GET', null, params),
  getNewArrivals: () => requestToApi('/menu/items/new-arrivals', 'GET'),
  getDishesByCategory: (category) => requestToApi(`/menu/items/category/${category}`, 'GET')
}

export const restaurantAPI = {
  getMenuForRestaurant: (id) => requestToApi(`/menu/restaurant/${id}`, "GET"),
  getDishesForMenu: (id) => requestToApi(`/menu/${id}/items`, 'GET'),
  getComboForRestaurant: (id) => requestToApi(`/menu/combo/restaurant/${id}`, 'GET'),
  getById: (id) => requestToApi(`/restaurant/${id}`, 'GET')
}

export const verifyAccount = (details, otp, dispatch) => {
  return (async () => {
    try {
      const res = await base.patch("/api/web/v1/u/verify/account", { otp });
      if (res?.data?.status === "success") {
        dispatch({ type: "LOGIN_USER", payload: details });
        localStorage.setItem("cls_udt", JSON.stringify(details));
        return res?.data?.status;
      } else {
        return "false";
      }
    } catch (error) {
      dispatch({ type: "ERROR", payload: error?.response?.data?.message });
    }
  })();
};

export const loginUser = (type, data) => {
  return async (dispatch) => {
    if (type === "buyer") {
      try {
        const res = await base.post("/api/web/v1/u/auth/signin", data);
        const details = {
          ...res?.data?.data,
          type: type === "buyer" ? "buyer" : "restaurant",
        };
        //dispatch({ type: "LOGIN_USER", payload: details });
        // return res?.data?.status;
        return { status: res?.data?.status, details };
      } catch (error) {
        console.log(error);
        dispatch({ type: "ERROR", payload: error?.response?.data?.message });
      }
    } 
    if (type === "restaurant") {
      const details = {
        username: data?.username,
        fullname: "Jeremiah Mills",
        contact_phone: "0557228597",
        location: "Baatsona Total Filling Station, Accra",
        city: "Spintex",
        email: "jeremiah@clickso.fr",
        type: "restaurant",
        expiry: new Date(Date.now() + 1 * 60 * 60 * 1000),
      };
      localStorage.setItem("cls_udt", JSON.stringify(details));
      dispatch({ type: "LOGIN_USER", payload: details });
      return "success";
    }
  };
};

export const rememberedData = () => {
  const userEmail = localStorage.getItem("cls_uem");
  const data = { email: null};
    return { ...data, email: JSON.parse(userEmail) };
};

export const signoutUser = () => {
  return (dispatch) => {
    localStorage.clear();
    dispatch({ type: "LOGOUT_USER" });
    dispatch({ type: "CLEAR_CART" });
    socket.disconnect();
    if (window.location.pathname === "/") {
      window.location.reload();
    } else {
      window.location.assign("/");
    }
  };
};
