import React, { useEffect, useState } from "react";
import PageHeader from "../../components/Header/PageHeader";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Box, Grid, Pagination, Skeleton, Typography } from "@mui/material";
import RestaurantItem from "../../components/Restaurant/Item/RestaurantItem";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Favorites = () => {
  const { t } = useTranslation()
  const [totalPages, setTotalPages] = useState(0);
  const [view, setView] = useState("grid");
  // const [favorites, setFavorites] = useState([]);
  const favorites = useSelector((state)=>state?.favorite)

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  const dispatch = useDispatch()

  const handlePageChange = (event, newValue) => {
    setPage(newValue);
  };

  return (
    <>
      <PageHeader page={t("Navbar.ProfileMenu.My Favorites")} type={1} link={"/account/favorites"} />
      <Box
        sx={{
          padding: {
            xs: "1rem",
            md: "4rem",
          },
        }}
      >
        <Grid container spacing={2}>
          {isLoading ? (
            [1, 2, 3, 4, 5, 6, 7, 8].map((el) => {
              return (
                <Grid key={el * 45} item xs={12} md={4}>
                  <Skeleton
                    sx={{ height: "22rem", mt: "-5.5rem" }}
                    animation="wave"
                  />
                </Grid>
              );
            })
          ) : favorites?.length > 0 ? (
            favorites?.map((fav, index) => {
              return (
                <Grid item xs={12} sm={6} md={3} key={fav?._id + index}>
                  <RestaurantItem
                    view={view}
                    id={fav?._id}
                    image={fav?.images ? fav?.images?.profile : ""}
                    deliveryTime={fav?.delivery?.time}
                    rating={fav?.reviewStats?.avgRating}
                    serviceFee={fav?.service?.feeApply}
                    discount={fav?.discount?.percentage}
                    dataX={fav?._id}
                  />
                </Grid>
              );
            })
          ) : (
            <Box textAlign={"center"} padding={"4rem"} width={"100%"}>
              <FavoriteIcon
                sx={{ width: "100px", height: "100px", color: "red" }}
                alt="Favorite Icon"
              />
              <Typography variant="h6" mt={3}>
                No Favorites
              </Typography>
              <Typography variant="body1" color={"GrayText"}>
                Add Favorites to your list{" "}
              </Typography>
            </Box>
          )}
          {/* Pagination */}
          {favorites?.length > 0 && (
            <Box
              my={"2rem"}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pagination
                size="medium"
                color="secondary"
                shape="rounded"
                variant="outlined"
                defaultPage={1}
                count={totalPages}
                onChange={handlePageChange}
              />
            </Box>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default Favorites;
