import { startTransition, useEffect, useState } from "react";
import i18next from "i18next";
import '../../utils/i18n'

const languageOptions = [
  { language: "English", code: "en" },
  { language: "French", code: "fr" },
];

const LanguageSelector = () => {
  const [language, setLanguage] = useState(i18next.language);

  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;

    // Use startTransition to prevent UI freezing
    startTransition(() => {
      setLanguage(selectedLanguage);
      i18next.changeLanguage(selectedLanguage);
    });


  };

  useEffect(() => {
    // document.body.dir = i18next.dir(i18next.language); // ✅ Corrected
    document.body.dir = "ltr"
  }, [language]); // ✅ Reacts to language changes

  return (
    <select
      id="language"
      value={language}
      onChange={handleLanguageChange}
    >
      {languageOptions.map(({ language, code }, key) => (
        <option value={code} key={key}>
          {language}
        </option>
      ))}
    </select>
  );
};

export default LanguageSelector;
